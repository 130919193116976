import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  date: any;
  numeric: any;
  timestamp: any;
  timestamptz: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "batch_winner" */
export type Batch_Winner = {
  __typename?: 'batch_winner';
  batch_num: Scalars['Int'];
  mobile_number: Scalars['String'];
  mobile_number_redacted: Scalars['String'];
  name: Scalars['String'];
  position: Scalars['Int'];
  prize: Scalars['String'];
  vehicle: Scalars['String'];
};

/** aggregated selection of "batch_winner" */
export type Batch_Winner_Aggregate = {
  __typename?: 'batch_winner_aggregate';
  aggregate?: Maybe<Batch_Winner_Aggregate_Fields>;
  nodes: Array<Batch_Winner>;
};

/** aggregate fields of "batch_winner" */
export type Batch_Winner_Aggregate_Fields = {
  __typename?: 'batch_winner_aggregate_fields';
  avg?: Maybe<Batch_Winner_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Batch_Winner_Max_Fields>;
  min?: Maybe<Batch_Winner_Min_Fields>;
  stddev?: Maybe<Batch_Winner_Stddev_Fields>;
  stddev_pop?: Maybe<Batch_Winner_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Batch_Winner_Stddev_Samp_Fields>;
  sum?: Maybe<Batch_Winner_Sum_Fields>;
  var_pop?: Maybe<Batch_Winner_Var_Pop_Fields>;
  var_samp?: Maybe<Batch_Winner_Var_Samp_Fields>;
  variance?: Maybe<Batch_Winner_Variance_Fields>;
};


/** aggregate fields of "batch_winner" */
export type Batch_Winner_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Batch_Winner_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Batch_Winner_Avg_Fields = {
  __typename?: 'batch_winner_avg_fields';
  batch_num?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "batch_winner". All fields are combined with a logical 'AND'. */
export type Batch_Winner_Bool_Exp = {
  _and?: InputMaybe<Array<Batch_Winner_Bool_Exp>>;
  _not?: InputMaybe<Batch_Winner_Bool_Exp>;
  _or?: InputMaybe<Array<Batch_Winner_Bool_Exp>>;
  batch_num?: InputMaybe<Int_Comparison_Exp>;
  mobile_number?: InputMaybe<String_Comparison_Exp>;
  mobile_number_redacted?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  position?: InputMaybe<Int_Comparison_Exp>;
  prize?: InputMaybe<String_Comparison_Exp>;
  vehicle?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "batch_winner" */
export enum Batch_Winner_Constraint {
  /** unique or primary key constraint */
  BatchWinnerPkey = 'batch_winner_pkey'
}

/** input type for incrementing numeric columns in table "batch_winner" */
export type Batch_Winner_Inc_Input = {
  batch_num?: InputMaybe<Scalars['Int']>;
  position?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "batch_winner" */
export type Batch_Winner_Insert_Input = {
  batch_num?: InputMaybe<Scalars['Int']>;
  mobile_number?: InputMaybe<Scalars['String']>;
  mobile_number_redacted?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  prize?: InputMaybe<Scalars['String']>;
  vehicle?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Batch_Winner_Max_Fields = {
  __typename?: 'batch_winner_max_fields';
  batch_num?: Maybe<Scalars['Int']>;
  mobile_number?: Maybe<Scalars['String']>;
  mobile_number_redacted?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  prize?: Maybe<Scalars['String']>;
  vehicle?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Batch_Winner_Min_Fields = {
  __typename?: 'batch_winner_min_fields';
  batch_num?: Maybe<Scalars['Int']>;
  mobile_number?: Maybe<Scalars['String']>;
  mobile_number_redacted?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  prize?: Maybe<Scalars['String']>;
  vehicle?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "batch_winner" */
export type Batch_Winner_Mutation_Response = {
  __typename?: 'batch_winner_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Batch_Winner>;
};

/** on conflict condition type for table "batch_winner" */
export type Batch_Winner_On_Conflict = {
  constraint: Batch_Winner_Constraint;
  update_columns?: Array<Batch_Winner_Update_Column>;
  where?: InputMaybe<Batch_Winner_Bool_Exp>;
};

/** Ordering options when selecting data from "batch_winner". */
export type Batch_Winner_Order_By = {
  batch_num?: InputMaybe<Order_By>;
  mobile_number?: InputMaybe<Order_By>;
  mobile_number_redacted?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  prize?: InputMaybe<Order_By>;
  vehicle?: InputMaybe<Order_By>;
};

/** primary key columns input for table: batch_winner */
export type Batch_Winner_Pk_Columns_Input = {
  batch_num: Scalars['Int'];
  position: Scalars['Int'];
  vehicle: Scalars['String'];
};

/** select columns of table "batch_winner" */
export enum Batch_Winner_Select_Column {
  /** column name */
  BatchNum = 'batch_num',
  /** column name */
  MobileNumber = 'mobile_number',
  /** column name */
  MobileNumberRedacted = 'mobile_number_redacted',
  /** column name */
  Name = 'name',
  /** column name */
  Position = 'position',
  /** column name */
  Prize = 'prize',
  /** column name */
  Vehicle = 'vehicle'
}

/** input type for updating data in table "batch_winner" */
export type Batch_Winner_Set_Input = {
  batch_num?: InputMaybe<Scalars['Int']>;
  mobile_number?: InputMaybe<Scalars['String']>;
  mobile_number_redacted?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  prize?: InputMaybe<Scalars['String']>;
  vehicle?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Batch_Winner_Stddev_Fields = {
  __typename?: 'batch_winner_stddev_fields';
  batch_num?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Batch_Winner_Stddev_Pop_Fields = {
  __typename?: 'batch_winner_stddev_pop_fields';
  batch_num?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Batch_Winner_Stddev_Samp_Fields = {
  __typename?: 'batch_winner_stddev_samp_fields';
  batch_num?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Batch_Winner_Sum_Fields = {
  __typename?: 'batch_winner_sum_fields';
  batch_num?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
};

/** update columns of table "batch_winner" */
export enum Batch_Winner_Update_Column {
  /** column name */
  BatchNum = 'batch_num',
  /** column name */
  MobileNumber = 'mobile_number',
  /** column name */
  MobileNumberRedacted = 'mobile_number_redacted',
  /** column name */
  Name = 'name',
  /** column name */
  Position = 'position',
  /** column name */
  Prize = 'prize',
  /** column name */
  Vehicle = 'vehicle'
}

/** aggregate var_pop on columns */
export type Batch_Winner_Var_Pop_Fields = {
  __typename?: 'batch_winner_var_pop_fields';
  batch_num?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Batch_Winner_Var_Samp_Fields = {
  __typename?: 'batch_winner_var_samp_fields';
  batch_num?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Batch_Winner_Variance_Fields = {
  __typename?: 'batch_winner_variance_fields';
  batch_num?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

export type Choose_Kol2_Args = {
  by_mobile_number?: InputMaybe<Scalars['String']>;
  new_kol_id?: InputMaybe<Scalars['Int']>;
};

export type Choose_Kol_Args = {
  by_mobile_number?: InputMaybe<Scalars['String']>;
  by_vehicle?: InputMaybe<Scalars['String']>;
  new_kol_id?: InputMaybe<Scalars['Int']>;
};

export type Create_Game_Progress_Snapshot_Args = {
  snapshot_key?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/**
 * Game progress for today only, will be copied to game_progress_history then reset every 00:00 WIB.
 *
 *
 * columns and relationships of "game_progress_live"
 */
export type Game_Progress_Live = {
  __typename?: 'game_progress_live';
  created_at: Scalars['timestamp'];
  gender?: Maybe<Scalars['String']>;
  kol_id?: Maybe<Scalars['Int']>;
  lap_count: Scalars['Int'];
  lap_progress: Scalars['Int'];
  lap_progress_km: Scalars['numeric'];
  ledger?: Maybe<Scalars['String']>;
  mobile_number: Scalars['String'];
  mobile_number_redacted: Scalars['String'];
  name: Scalars['String'];
  order_count: Scalars['Int'];
  order_id?: Maybe<Scalars['String']>;
  order_increment?: Maybe<Scalars['Int']>;
  order_kind?: Maybe<Scalars['String']>;
  order_product?: Maybe<Scalars['String']>;
  order_time?: Maybe<Scalars['timestamptz']>;
  order_volume?: Maybe<Scalars['numeric']>;
  position?: Maybe<Scalars['Int']>;
  prev_lap_count: Scalars['Int'];
  prev_lap_progress: Scalars['Int'];
  prev_lap_progress_km: Scalars['numeric'];
  prev_order_count: Scalars['Int'];
  prev_order_id?: Maybe<Scalars['String']>;
  prev_order_increment?: Maybe<Scalars['Int']>;
  prev_order_kind?: Maybe<Scalars['String']>;
  prev_order_product?: Maybe<Scalars['String']>;
  prev_order_time?: Maybe<Scalars['timestamptz']>;
  prev_total_progress: Scalars['Int'];
  total_progress: Scalars['Int'];
  vehicle: Scalars['String'];
};

/** aggregated selection of "game_progress_live" */
export type Game_Progress_Live_Aggregate = {
  __typename?: 'game_progress_live_aggregate';
  aggregate?: Maybe<Game_Progress_Live_Aggregate_Fields>;
  nodes: Array<Game_Progress_Live>;
};

/** aggregate fields of "game_progress_live" */
export type Game_Progress_Live_Aggregate_Fields = {
  __typename?: 'game_progress_live_aggregate_fields';
  avg?: Maybe<Game_Progress_Live_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Game_Progress_Live_Max_Fields>;
  min?: Maybe<Game_Progress_Live_Min_Fields>;
  stddev?: Maybe<Game_Progress_Live_Stddev_Fields>;
  stddev_pop?: Maybe<Game_Progress_Live_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Game_Progress_Live_Stddev_Samp_Fields>;
  sum?: Maybe<Game_Progress_Live_Sum_Fields>;
  var_pop?: Maybe<Game_Progress_Live_Var_Pop_Fields>;
  var_samp?: Maybe<Game_Progress_Live_Var_Samp_Fields>;
  variance?: Maybe<Game_Progress_Live_Variance_Fields>;
};


/** aggregate fields of "game_progress_live" */
export type Game_Progress_Live_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Game_Progress_Live_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Game_Progress_Live_Avg_Fields = {
  __typename?: 'game_progress_live_avg_fields';
  kol_id?: Maybe<Scalars['Float']>;
  lap_count?: Maybe<Scalars['Float']>;
  lap_progress?: Maybe<Scalars['Float']>;
  lap_progress_km?: Maybe<Scalars['Float']>;
  order_count?: Maybe<Scalars['Float']>;
  order_increment?: Maybe<Scalars['Float']>;
  order_volume?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  prev_lap_count?: Maybe<Scalars['Float']>;
  prev_lap_progress?: Maybe<Scalars['Float']>;
  prev_lap_progress_km?: Maybe<Scalars['Float']>;
  prev_order_count?: Maybe<Scalars['Float']>;
  prev_order_increment?: Maybe<Scalars['Float']>;
  prev_total_progress?: Maybe<Scalars['Float']>;
  total_progress?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "game_progress_live". All fields are combined with a logical 'AND'. */
export type Game_Progress_Live_Bool_Exp = {
  _and?: InputMaybe<Array<Game_Progress_Live_Bool_Exp>>;
  _not?: InputMaybe<Game_Progress_Live_Bool_Exp>;
  _or?: InputMaybe<Array<Game_Progress_Live_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  gender?: InputMaybe<String_Comparison_Exp>;
  kol_id?: InputMaybe<Int_Comparison_Exp>;
  lap_count?: InputMaybe<Int_Comparison_Exp>;
  lap_progress?: InputMaybe<Int_Comparison_Exp>;
  lap_progress_km?: InputMaybe<Numeric_Comparison_Exp>;
  ledger?: InputMaybe<String_Comparison_Exp>;
  mobile_number?: InputMaybe<String_Comparison_Exp>;
  mobile_number_redacted?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order_count?: InputMaybe<Int_Comparison_Exp>;
  order_id?: InputMaybe<String_Comparison_Exp>;
  order_increment?: InputMaybe<Int_Comparison_Exp>;
  order_kind?: InputMaybe<String_Comparison_Exp>;
  order_product?: InputMaybe<String_Comparison_Exp>;
  order_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  order_volume?: InputMaybe<Numeric_Comparison_Exp>;
  position?: InputMaybe<Int_Comparison_Exp>;
  prev_lap_count?: InputMaybe<Int_Comparison_Exp>;
  prev_lap_progress?: InputMaybe<Int_Comparison_Exp>;
  prev_lap_progress_km?: InputMaybe<Numeric_Comparison_Exp>;
  prev_order_count?: InputMaybe<Int_Comparison_Exp>;
  prev_order_id?: InputMaybe<String_Comparison_Exp>;
  prev_order_increment?: InputMaybe<Int_Comparison_Exp>;
  prev_order_kind?: InputMaybe<String_Comparison_Exp>;
  prev_order_product?: InputMaybe<String_Comparison_Exp>;
  prev_order_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  prev_total_progress?: InputMaybe<Int_Comparison_Exp>;
  total_progress?: InputMaybe<Int_Comparison_Exp>;
  vehicle?: InputMaybe<String_Comparison_Exp>;
};

/** columns and relationships of "game_progress_live_compact" */
export type Game_Progress_Live_Compact = {
  __typename?: 'game_progress_live_compact';
  kol_id?: Maybe<Scalars['Int']>;
  lap_count?: Maybe<Scalars['Int']>;
  lap_progress?: Maybe<Scalars['Int']>;
  lap_progress_km?: Maybe<Scalars['numeric']>;
  mobile_number_redacted?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  self?: Maybe<Scalars['Boolean']>;
  total_progress?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "game_progress_live_compact" */
export type Game_Progress_Live_Compact_Aggregate = {
  __typename?: 'game_progress_live_compact_aggregate';
  aggregate?: Maybe<Game_Progress_Live_Compact_Aggregate_Fields>;
  nodes: Array<Game_Progress_Live_Compact>;
};

/** aggregate fields of "game_progress_live_compact" */
export type Game_Progress_Live_Compact_Aggregate_Fields = {
  __typename?: 'game_progress_live_compact_aggregate_fields';
  avg?: Maybe<Game_Progress_Live_Compact_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Game_Progress_Live_Compact_Max_Fields>;
  min?: Maybe<Game_Progress_Live_Compact_Min_Fields>;
  stddev?: Maybe<Game_Progress_Live_Compact_Stddev_Fields>;
  stddev_pop?: Maybe<Game_Progress_Live_Compact_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Game_Progress_Live_Compact_Stddev_Samp_Fields>;
  sum?: Maybe<Game_Progress_Live_Compact_Sum_Fields>;
  var_pop?: Maybe<Game_Progress_Live_Compact_Var_Pop_Fields>;
  var_samp?: Maybe<Game_Progress_Live_Compact_Var_Samp_Fields>;
  variance?: Maybe<Game_Progress_Live_Compact_Variance_Fields>;
};


/** aggregate fields of "game_progress_live_compact" */
export type Game_Progress_Live_Compact_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Game_Progress_Live_Compact_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Game_Progress_Live_Compact_Avg_Fields = {
  __typename?: 'game_progress_live_compact_avg_fields';
  kol_id?: Maybe<Scalars['Float']>;
  lap_count?: Maybe<Scalars['Float']>;
  lap_progress?: Maybe<Scalars['Float']>;
  lap_progress_km?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  total_progress?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "game_progress_live_compact". All fields are combined with a logical 'AND'. */
export type Game_Progress_Live_Compact_Bool_Exp = {
  _and?: InputMaybe<Array<Game_Progress_Live_Compact_Bool_Exp>>;
  _not?: InputMaybe<Game_Progress_Live_Compact_Bool_Exp>;
  _or?: InputMaybe<Array<Game_Progress_Live_Compact_Bool_Exp>>;
  kol_id?: InputMaybe<Int_Comparison_Exp>;
  lap_count?: InputMaybe<Int_Comparison_Exp>;
  lap_progress?: InputMaybe<Int_Comparison_Exp>;
  lap_progress_km?: InputMaybe<Numeric_Comparison_Exp>;
  mobile_number_redacted?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  position?: InputMaybe<Int_Comparison_Exp>;
  self?: InputMaybe<Boolean_Comparison_Exp>;
  total_progress?: InputMaybe<Int_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "game_progress_live_compact" */
export type Game_Progress_Live_Compact_Inc_Input = {
  kol_id?: InputMaybe<Scalars['Int']>;
  lap_count?: InputMaybe<Scalars['Int']>;
  lap_progress?: InputMaybe<Scalars['Int']>;
  lap_progress_km?: InputMaybe<Scalars['numeric']>;
  position?: InputMaybe<Scalars['Int']>;
  total_progress?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "game_progress_live_compact" */
export type Game_Progress_Live_Compact_Insert_Input = {
  kol_id?: InputMaybe<Scalars['Int']>;
  lap_count?: InputMaybe<Scalars['Int']>;
  lap_progress?: InputMaybe<Scalars['Int']>;
  lap_progress_km?: InputMaybe<Scalars['numeric']>;
  mobile_number_redacted?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  self?: InputMaybe<Scalars['Boolean']>;
  total_progress?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Game_Progress_Live_Compact_Max_Fields = {
  __typename?: 'game_progress_live_compact_max_fields';
  kol_id?: Maybe<Scalars['Int']>;
  lap_count?: Maybe<Scalars['Int']>;
  lap_progress?: Maybe<Scalars['Int']>;
  lap_progress_km?: Maybe<Scalars['numeric']>;
  mobile_number_redacted?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  total_progress?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Game_Progress_Live_Compact_Min_Fields = {
  __typename?: 'game_progress_live_compact_min_fields';
  kol_id?: Maybe<Scalars['Int']>;
  lap_count?: Maybe<Scalars['Int']>;
  lap_progress?: Maybe<Scalars['Int']>;
  lap_progress_km?: Maybe<Scalars['numeric']>;
  mobile_number_redacted?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  total_progress?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "game_progress_live_compact" */
export type Game_Progress_Live_Compact_Mutation_Response = {
  __typename?: 'game_progress_live_compact_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Game_Progress_Live_Compact>;
};

/** Ordering options when selecting data from "game_progress_live_compact". */
export type Game_Progress_Live_Compact_Order_By = {
  kol_id?: InputMaybe<Order_By>;
  lap_count?: InputMaybe<Order_By>;
  lap_progress?: InputMaybe<Order_By>;
  lap_progress_km?: InputMaybe<Order_By>;
  mobile_number_redacted?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  self?: InputMaybe<Order_By>;
  total_progress?: InputMaybe<Order_By>;
};

/** select columns of table "game_progress_live_compact" */
export enum Game_Progress_Live_Compact_Select_Column {
  /** column name */
  KolId = 'kol_id',
  /** column name */
  LapCount = 'lap_count',
  /** column name */
  LapProgress = 'lap_progress',
  /** column name */
  LapProgressKm = 'lap_progress_km',
  /** column name */
  MobileNumberRedacted = 'mobile_number_redacted',
  /** column name */
  Name = 'name',
  /** column name */
  Position = 'position',
  /** column name */
  Self = 'self',
  /** column name */
  TotalProgress = 'total_progress'
}

/** input type for updating data in table "game_progress_live_compact" */
export type Game_Progress_Live_Compact_Set_Input = {
  kol_id?: InputMaybe<Scalars['Int']>;
  lap_count?: InputMaybe<Scalars['Int']>;
  lap_progress?: InputMaybe<Scalars['Int']>;
  lap_progress_km?: InputMaybe<Scalars['numeric']>;
  mobile_number_redacted?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  self?: InputMaybe<Scalars['Boolean']>;
  total_progress?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Game_Progress_Live_Compact_Stddev_Fields = {
  __typename?: 'game_progress_live_compact_stddev_fields';
  kol_id?: Maybe<Scalars['Float']>;
  lap_count?: Maybe<Scalars['Float']>;
  lap_progress?: Maybe<Scalars['Float']>;
  lap_progress_km?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  total_progress?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Game_Progress_Live_Compact_Stddev_Pop_Fields = {
  __typename?: 'game_progress_live_compact_stddev_pop_fields';
  kol_id?: Maybe<Scalars['Float']>;
  lap_count?: Maybe<Scalars['Float']>;
  lap_progress?: Maybe<Scalars['Float']>;
  lap_progress_km?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  total_progress?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Game_Progress_Live_Compact_Stddev_Samp_Fields = {
  __typename?: 'game_progress_live_compact_stddev_samp_fields';
  kol_id?: Maybe<Scalars['Float']>;
  lap_count?: Maybe<Scalars['Float']>;
  lap_progress?: Maybe<Scalars['Float']>;
  lap_progress_km?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  total_progress?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Game_Progress_Live_Compact_Sum_Fields = {
  __typename?: 'game_progress_live_compact_sum_fields';
  kol_id?: Maybe<Scalars['Int']>;
  lap_count?: Maybe<Scalars['Int']>;
  lap_progress?: Maybe<Scalars['Int']>;
  lap_progress_km?: Maybe<Scalars['numeric']>;
  position?: Maybe<Scalars['Int']>;
  total_progress?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Game_Progress_Live_Compact_Var_Pop_Fields = {
  __typename?: 'game_progress_live_compact_var_pop_fields';
  kol_id?: Maybe<Scalars['Float']>;
  lap_count?: Maybe<Scalars['Float']>;
  lap_progress?: Maybe<Scalars['Float']>;
  lap_progress_km?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  total_progress?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Game_Progress_Live_Compact_Var_Samp_Fields = {
  __typename?: 'game_progress_live_compact_var_samp_fields';
  kol_id?: Maybe<Scalars['Float']>;
  lap_count?: Maybe<Scalars['Float']>;
  lap_progress?: Maybe<Scalars['Float']>;
  lap_progress_km?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  total_progress?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Game_Progress_Live_Compact_Variance_Fields = {
  __typename?: 'game_progress_live_compact_variance_fields';
  kol_id?: Maybe<Scalars['Float']>;
  lap_count?: Maybe<Scalars['Float']>;
  lap_progress?: Maybe<Scalars['Float']>;
  lap_progress_km?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  total_progress?: Maybe<Scalars['Float']>;
};

/** unique or primary key constraints on table "game_progress_live" */
export enum Game_Progress_Live_Constraint {
  /** unique or primary key constraint */
  GameProgressLivePkey = 'game_progress_live_pkey'
}

/** input type for incrementing numeric columns in table "game_progress_live" */
export type Game_Progress_Live_Inc_Input = {
  kol_id?: InputMaybe<Scalars['Int']>;
  lap_count?: InputMaybe<Scalars['Int']>;
  lap_progress?: InputMaybe<Scalars['Int']>;
  lap_progress_km?: InputMaybe<Scalars['numeric']>;
  order_count?: InputMaybe<Scalars['Int']>;
  order_increment?: InputMaybe<Scalars['Int']>;
  order_volume?: InputMaybe<Scalars['numeric']>;
  position?: InputMaybe<Scalars['Int']>;
  prev_lap_count?: InputMaybe<Scalars['Int']>;
  prev_lap_progress?: InputMaybe<Scalars['Int']>;
  prev_lap_progress_km?: InputMaybe<Scalars['numeric']>;
  prev_order_count?: InputMaybe<Scalars['Int']>;
  prev_order_increment?: InputMaybe<Scalars['Int']>;
  prev_total_progress?: InputMaybe<Scalars['Int']>;
  total_progress?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "game_progress_live" */
export type Game_Progress_Live_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  gender?: InputMaybe<Scalars['String']>;
  kol_id?: InputMaybe<Scalars['Int']>;
  lap_count?: InputMaybe<Scalars['Int']>;
  lap_progress?: InputMaybe<Scalars['Int']>;
  lap_progress_km?: InputMaybe<Scalars['numeric']>;
  ledger?: InputMaybe<Scalars['String']>;
  mobile_number?: InputMaybe<Scalars['String']>;
  mobile_number_redacted?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order_count?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['String']>;
  order_increment?: InputMaybe<Scalars['Int']>;
  order_kind?: InputMaybe<Scalars['String']>;
  order_product?: InputMaybe<Scalars['String']>;
  order_time?: InputMaybe<Scalars['timestamptz']>;
  order_volume?: InputMaybe<Scalars['numeric']>;
  position?: InputMaybe<Scalars['Int']>;
  prev_lap_count?: InputMaybe<Scalars['Int']>;
  prev_lap_progress?: InputMaybe<Scalars['Int']>;
  prev_lap_progress_km?: InputMaybe<Scalars['numeric']>;
  prev_order_count?: InputMaybe<Scalars['Int']>;
  prev_order_id?: InputMaybe<Scalars['String']>;
  prev_order_increment?: InputMaybe<Scalars['Int']>;
  prev_order_kind?: InputMaybe<Scalars['String']>;
  prev_order_product?: InputMaybe<Scalars['String']>;
  prev_order_time?: InputMaybe<Scalars['timestamptz']>;
  prev_total_progress?: InputMaybe<Scalars['Int']>;
  total_progress?: InputMaybe<Scalars['Int']>;
  vehicle?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Game_Progress_Live_Max_Fields = {
  __typename?: 'game_progress_live_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  gender?: Maybe<Scalars['String']>;
  kol_id?: Maybe<Scalars['Int']>;
  lap_count?: Maybe<Scalars['Int']>;
  lap_progress?: Maybe<Scalars['Int']>;
  lap_progress_km?: Maybe<Scalars['numeric']>;
  ledger?: Maybe<Scalars['String']>;
  mobile_number?: Maybe<Scalars['String']>;
  mobile_number_redacted?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order_count?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['String']>;
  order_increment?: Maybe<Scalars['Int']>;
  order_kind?: Maybe<Scalars['String']>;
  order_product?: Maybe<Scalars['String']>;
  order_time?: Maybe<Scalars['timestamptz']>;
  order_volume?: Maybe<Scalars['numeric']>;
  position?: Maybe<Scalars['Int']>;
  prev_lap_count?: Maybe<Scalars['Int']>;
  prev_lap_progress?: Maybe<Scalars['Int']>;
  prev_lap_progress_km?: Maybe<Scalars['numeric']>;
  prev_order_count?: Maybe<Scalars['Int']>;
  prev_order_id?: Maybe<Scalars['String']>;
  prev_order_increment?: Maybe<Scalars['Int']>;
  prev_order_kind?: Maybe<Scalars['String']>;
  prev_order_product?: Maybe<Scalars['String']>;
  prev_order_time?: Maybe<Scalars['timestamptz']>;
  prev_total_progress?: Maybe<Scalars['Int']>;
  total_progress?: Maybe<Scalars['Int']>;
  vehicle?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Game_Progress_Live_Min_Fields = {
  __typename?: 'game_progress_live_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  gender?: Maybe<Scalars['String']>;
  kol_id?: Maybe<Scalars['Int']>;
  lap_count?: Maybe<Scalars['Int']>;
  lap_progress?: Maybe<Scalars['Int']>;
  lap_progress_km?: Maybe<Scalars['numeric']>;
  ledger?: Maybe<Scalars['String']>;
  mobile_number?: Maybe<Scalars['String']>;
  mobile_number_redacted?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order_count?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['String']>;
  order_increment?: Maybe<Scalars['Int']>;
  order_kind?: Maybe<Scalars['String']>;
  order_product?: Maybe<Scalars['String']>;
  order_time?: Maybe<Scalars['timestamptz']>;
  order_volume?: Maybe<Scalars['numeric']>;
  position?: Maybe<Scalars['Int']>;
  prev_lap_count?: Maybe<Scalars['Int']>;
  prev_lap_progress?: Maybe<Scalars['Int']>;
  prev_lap_progress_km?: Maybe<Scalars['numeric']>;
  prev_order_count?: Maybe<Scalars['Int']>;
  prev_order_id?: Maybe<Scalars['String']>;
  prev_order_increment?: Maybe<Scalars['Int']>;
  prev_order_kind?: Maybe<Scalars['String']>;
  prev_order_product?: Maybe<Scalars['String']>;
  prev_order_time?: Maybe<Scalars['timestamptz']>;
  prev_total_progress?: Maybe<Scalars['Int']>;
  total_progress?: Maybe<Scalars['Int']>;
  vehicle?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "game_progress_live" */
export type Game_Progress_Live_Mutation_Response = {
  __typename?: 'game_progress_live_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Game_Progress_Live>;
};

/** on conflict condition type for table "game_progress_live" */
export type Game_Progress_Live_On_Conflict = {
  constraint: Game_Progress_Live_Constraint;
  update_columns?: Array<Game_Progress_Live_Update_Column>;
  where?: InputMaybe<Game_Progress_Live_Bool_Exp>;
};

/** Ordering options when selecting data from "game_progress_live". */
export type Game_Progress_Live_Order_By = {
  created_at?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  kol_id?: InputMaybe<Order_By>;
  lap_count?: InputMaybe<Order_By>;
  lap_progress?: InputMaybe<Order_By>;
  lap_progress_km?: InputMaybe<Order_By>;
  ledger?: InputMaybe<Order_By>;
  mobile_number?: InputMaybe<Order_By>;
  mobile_number_redacted?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order_count?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  order_increment?: InputMaybe<Order_By>;
  order_kind?: InputMaybe<Order_By>;
  order_product?: InputMaybe<Order_By>;
  order_time?: InputMaybe<Order_By>;
  order_volume?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  prev_lap_count?: InputMaybe<Order_By>;
  prev_lap_progress?: InputMaybe<Order_By>;
  prev_lap_progress_km?: InputMaybe<Order_By>;
  prev_order_count?: InputMaybe<Order_By>;
  prev_order_id?: InputMaybe<Order_By>;
  prev_order_increment?: InputMaybe<Order_By>;
  prev_order_kind?: InputMaybe<Order_By>;
  prev_order_product?: InputMaybe<Order_By>;
  prev_order_time?: InputMaybe<Order_By>;
  prev_total_progress?: InputMaybe<Order_By>;
  total_progress?: InputMaybe<Order_By>;
  vehicle?: InputMaybe<Order_By>;
};

/** primary key columns input for table: game_progress_live */
export type Game_Progress_Live_Pk_Columns_Input = {
  mobile_number: Scalars['String'];
  vehicle: Scalars['String'];
};

/** select columns of table "game_progress_live" */
export enum Game_Progress_Live_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Gender = 'gender',
  /** column name */
  KolId = 'kol_id',
  /** column name */
  LapCount = 'lap_count',
  /** column name */
  LapProgress = 'lap_progress',
  /** column name */
  LapProgressKm = 'lap_progress_km',
  /** column name */
  Ledger = 'ledger',
  /** column name */
  MobileNumber = 'mobile_number',
  /** column name */
  MobileNumberRedacted = 'mobile_number_redacted',
  /** column name */
  Name = 'name',
  /** column name */
  OrderCount = 'order_count',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  OrderIncrement = 'order_increment',
  /** column name */
  OrderKind = 'order_kind',
  /** column name */
  OrderProduct = 'order_product',
  /** column name */
  OrderTime = 'order_time',
  /** column name */
  OrderVolume = 'order_volume',
  /** column name */
  Position = 'position',
  /** column name */
  PrevLapCount = 'prev_lap_count',
  /** column name */
  PrevLapProgress = 'prev_lap_progress',
  /** column name */
  PrevLapProgressKm = 'prev_lap_progress_km',
  /** column name */
  PrevOrderCount = 'prev_order_count',
  /** column name */
  PrevOrderId = 'prev_order_id',
  /** column name */
  PrevOrderIncrement = 'prev_order_increment',
  /** column name */
  PrevOrderKind = 'prev_order_kind',
  /** column name */
  PrevOrderProduct = 'prev_order_product',
  /** column name */
  PrevOrderTime = 'prev_order_time',
  /** column name */
  PrevTotalProgress = 'prev_total_progress',
  /** column name */
  TotalProgress = 'total_progress',
  /** column name */
  Vehicle = 'vehicle'
}

/** input type for updating data in table "game_progress_live" */
export type Game_Progress_Live_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  gender?: InputMaybe<Scalars['String']>;
  kol_id?: InputMaybe<Scalars['Int']>;
  lap_count?: InputMaybe<Scalars['Int']>;
  lap_progress?: InputMaybe<Scalars['Int']>;
  lap_progress_km?: InputMaybe<Scalars['numeric']>;
  ledger?: InputMaybe<Scalars['String']>;
  mobile_number?: InputMaybe<Scalars['String']>;
  mobile_number_redacted?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order_count?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['String']>;
  order_increment?: InputMaybe<Scalars['Int']>;
  order_kind?: InputMaybe<Scalars['String']>;
  order_product?: InputMaybe<Scalars['String']>;
  order_time?: InputMaybe<Scalars['timestamptz']>;
  order_volume?: InputMaybe<Scalars['numeric']>;
  position?: InputMaybe<Scalars['Int']>;
  prev_lap_count?: InputMaybe<Scalars['Int']>;
  prev_lap_progress?: InputMaybe<Scalars['Int']>;
  prev_lap_progress_km?: InputMaybe<Scalars['numeric']>;
  prev_order_count?: InputMaybe<Scalars['Int']>;
  prev_order_id?: InputMaybe<Scalars['String']>;
  prev_order_increment?: InputMaybe<Scalars['Int']>;
  prev_order_kind?: InputMaybe<Scalars['String']>;
  prev_order_product?: InputMaybe<Scalars['String']>;
  prev_order_time?: InputMaybe<Scalars['timestamptz']>;
  prev_total_progress?: InputMaybe<Scalars['Int']>;
  total_progress?: InputMaybe<Scalars['Int']>;
  vehicle?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Game_Progress_Live_Stddev_Fields = {
  __typename?: 'game_progress_live_stddev_fields';
  kol_id?: Maybe<Scalars['Float']>;
  lap_count?: Maybe<Scalars['Float']>;
  lap_progress?: Maybe<Scalars['Float']>;
  lap_progress_km?: Maybe<Scalars['Float']>;
  order_count?: Maybe<Scalars['Float']>;
  order_increment?: Maybe<Scalars['Float']>;
  order_volume?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  prev_lap_count?: Maybe<Scalars['Float']>;
  prev_lap_progress?: Maybe<Scalars['Float']>;
  prev_lap_progress_km?: Maybe<Scalars['Float']>;
  prev_order_count?: Maybe<Scalars['Float']>;
  prev_order_increment?: Maybe<Scalars['Float']>;
  prev_total_progress?: Maybe<Scalars['Float']>;
  total_progress?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Game_Progress_Live_Stddev_Pop_Fields = {
  __typename?: 'game_progress_live_stddev_pop_fields';
  kol_id?: Maybe<Scalars['Float']>;
  lap_count?: Maybe<Scalars['Float']>;
  lap_progress?: Maybe<Scalars['Float']>;
  lap_progress_km?: Maybe<Scalars['Float']>;
  order_count?: Maybe<Scalars['Float']>;
  order_increment?: Maybe<Scalars['Float']>;
  order_volume?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  prev_lap_count?: Maybe<Scalars['Float']>;
  prev_lap_progress?: Maybe<Scalars['Float']>;
  prev_lap_progress_km?: Maybe<Scalars['Float']>;
  prev_order_count?: Maybe<Scalars['Float']>;
  prev_order_increment?: Maybe<Scalars['Float']>;
  prev_total_progress?: Maybe<Scalars['Float']>;
  total_progress?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Game_Progress_Live_Stddev_Samp_Fields = {
  __typename?: 'game_progress_live_stddev_samp_fields';
  kol_id?: Maybe<Scalars['Float']>;
  lap_count?: Maybe<Scalars['Float']>;
  lap_progress?: Maybe<Scalars['Float']>;
  lap_progress_km?: Maybe<Scalars['Float']>;
  order_count?: Maybe<Scalars['Float']>;
  order_increment?: Maybe<Scalars['Float']>;
  order_volume?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  prev_lap_count?: Maybe<Scalars['Float']>;
  prev_lap_progress?: Maybe<Scalars['Float']>;
  prev_lap_progress_km?: Maybe<Scalars['Float']>;
  prev_order_count?: Maybe<Scalars['Float']>;
  prev_order_increment?: Maybe<Scalars['Float']>;
  prev_total_progress?: Maybe<Scalars['Float']>;
  total_progress?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Game_Progress_Live_Sum_Fields = {
  __typename?: 'game_progress_live_sum_fields';
  kol_id?: Maybe<Scalars['Int']>;
  lap_count?: Maybe<Scalars['Int']>;
  lap_progress?: Maybe<Scalars['Int']>;
  lap_progress_km?: Maybe<Scalars['numeric']>;
  order_count?: Maybe<Scalars['Int']>;
  order_increment?: Maybe<Scalars['Int']>;
  order_volume?: Maybe<Scalars['numeric']>;
  position?: Maybe<Scalars['Int']>;
  prev_lap_count?: Maybe<Scalars['Int']>;
  prev_lap_progress?: Maybe<Scalars['Int']>;
  prev_lap_progress_km?: Maybe<Scalars['numeric']>;
  prev_order_count?: Maybe<Scalars['Int']>;
  prev_order_increment?: Maybe<Scalars['Int']>;
  prev_total_progress?: Maybe<Scalars['Int']>;
  total_progress?: Maybe<Scalars['Int']>;
};

/** update columns of table "game_progress_live" */
export enum Game_Progress_Live_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Gender = 'gender',
  /** column name */
  KolId = 'kol_id',
  /** column name */
  LapCount = 'lap_count',
  /** column name */
  LapProgress = 'lap_progress',
  /** column name */
  LapProgressKm = 'lap_progress_km',
  /** column name */
  Ledger = 'ledger',
  /** column name */
  MobileNumber = 'mobile_number',
  /** column name */
  MobileNumberRedacted = 'mobile_number_redacted',
  /** column name */
  Name = 'name',
  /** column name */
  OrderCount = 'order_count',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  OrderIncrement = 'order_increment',
  /** column name */
  OrderKind = 'order_kind',
  /** column name */
  OrderProduct = 'order_product',
  /** column name */
  OrderTime = 'order_time',
  /** column name */
  OrderVolume = 'order_volume',
  /** column name */
  Position = 'position',
  /** column name */
  PrevLapCount = 'prev_lap_count',
  /** column name */
  PrevLapProgress = 'prev_lap_progress',
  /** column name */
  PrevLapProgressKm = 'prev_lap_progress_km',
  /** column name */
  PrevOrderCount = 'prev_order_count',
  /** column name */
  PrevOrderId = 'prev_order_id',
  /** column name */
  PrevOrderIncrement = 'prev_order_increment',
  /** column name */
  PrevOrderKind = 'prev_order_kind',
  /** column name */
  PrevOrderProduct = 'prev_order_product',
  /** column name */
  PrevOrderTime = 'prev_order_time',
  /** column name */
  PrevTotalProgress = 'prev_total_progress',
  /** column name */
  TotalProgress = 'total_progress',
  /** column name */
  Vehicle = 'vehicle'
}

/** aggregate var_pop on columns */
export type Game_Progress_Live_Var_Pop_Fields = {
  __typename?: 'game_progress_live_var_pop_fields';
  kol_id?: Maybe<Scalars['Float']>;
  lap_count?: Maybe<Scalars['Float']>;
  lap_progress?: Maybe<Scalars['Float']>;
  lap_progress_km?: Maybe<Scalars['Float']>;
  order_count?: Maybe<Scalars['Float']>;
  order_increment?: Maybe<Scalars['Float']>;
  order_volume?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  prev_lap_count?: Maybe<Scalars['Float']>;
  prev_lap_progress?: Maybe<Scalars['Float']>;
  prev_lap_progress_km?: Maybe<Scalars['Float']>;
  prev_order_count?: Maybe<Scalars['Float']>;
  prev_order_increment?: Maybe<Scalars['Float']>;
  prev_total_progress?: Maybe<Scalars['Float']>;
  total_progress?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Game_Progress_Live_Var_Samp_Fields = {
  __typename?: 'game_progress_live_var_samp_fields';
  kol_id?: Maybe<Scalars['Float']>;
  lap_count?: Maybe<Scalars['Float']>;
  lap_progress?: Maybe<Scalars['Float']>;
  lap_progress_km?: Maybe<Scalars['Float']>;
  order_count?: Maybe<Scalars['Float']>;
  order_increment?: Maybe<Scalars['Float']>;
  order_volume?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  prev_lap_count?: Maybe<Scalars['Float']>;
  prev_lap_progress?: Maybe<Scalars['Float']>;
  prev_lap_progress_km?: Maybe<Scalars['Float']>;
  prev_order_count?: Maybe<Scalars['Float']>;
  prev_order_increment?: Maybe<Scalars['Float']>;
  prev_total_progress?: Maybe<Scalars['Float']>;
  total_progress?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Game_Progress_Live_Variance_Fields = {
  __typename?: 'game_progress_live_variance_fields';
  kol_id?: Maybe<Scalars['Float']>;
  lap_count?: Maybe<Scalars['Float']>;
  lap_progress?: Maybe<Scalars['Float']>;
  lap_progress_km?: Maybe<Scalars['Float']>;
  order_count?: Maybe<Scalars['Float']>;
  order_increment?: Maybe<Scalars['Float']>;
  order_volume?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  prev_lap_count?: Maybe<Scalars['Float']>;
  prev_lap_progress?: Maybe<Scalars['Float']>;
  prev_lap_progress_km?: Maybe<Scalars['Float']>;
  prev_order_count?: Maybe<Scalars['Float']>;
  prev_order_increment?: Maybe<Scalars['Float']>;
  prev_total_progress?: Maybe<Scalars['Float']>;
  total_progress?: Maybe<Scalars['Float']>;
};

/**
 * Game progress snapshots.
 *
 *
 * columns and relationships of "game_progress_snapshot"
 */
export type Game_Progress_Snapshot = {
  __typename?: 'game_progress_snapshot';
  created_at?: Maybe<Scalars['timestamptz']>;
  gender?: Maybe<Scalars['String']>;
  kol_id?: Maybe<Scalars['Int']>;
  lap_count: Scalars['Int'];
  lap_progress: Scalars['Int'];
  lap_progress_km: Scalars['numeric'];
  ledger?: Maybe<Scalars['String']>;
  mobile_number: Scalars['String'];
  mobile_number_redacted: Scalars['String'];
  name: Scalars['String'];
  order_count: Scalars['Int'];
  order_id?: Maybe<Scalars['String']>;
  order_increment?: Maybe<Scalars['Int']>;
  order_kind?: Maybe<Scalars['String']>;
  order_product?: Maybe<Scalars['String']>;
  order_time?: Maybe<Scalars['timestamptz']>;
  order_volume?: Maybe<Scalars['numeric']>;
  position?: Maybe<Scalars['Int']>;
  prev_lap_count: Scalars['Int'];
  prev_lap_progress: Scalars['Int'];
  prev_lap_progress_km: Scalars['numeric'];
  prev_order_count: Scalars['Int'];
  prev_order_id?: Maybe<Scalars['String']>;
  prev_order_increment?: Maybe<Scalars['Int']>;
  prev_order_kind?: Maybe<Scalars['String']>;
  prev_order_product?: Maybe<Scalars['String']>;
  prev_order_time?: Maybe<Scalars['timestamptz']>;
  prev_total_progress: Scalars['Int'];
  snapshot_key: Scalars['String'];
  total_progress: Scalars['Int'];
  vehicle: Scalars['String'];
};

/** aggregated selection of "game_progress_snapshot" */
export type Game_Progress_Snapshot_Aggregate = {
  __typename?: 'game_progress_snapshot_aggregate';
  aggregate?: Maybe<Game_Progress_Snapshot_Aggregate_Fields>;
  nodes: Array<Game_Progress_Snapshot>;
};

/** aggregate fields of "game_progress_snapshot" */
export type Game_Progress_Snapshot_Aggregate_Fields = {
  __typename?: 'game_progress_snapshot_aggregate_fields';
  avg?: Maybe<Game_Progress_Snapshot_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Game_Progress_Snapshot_Max_Fields>;
  min?: Maybe<Game_Progress_Snapshot_Min_Fields>;
  stddev?: Maybe<Game_Progress_Snapshot_Stddev_Fields>;
  stddev_pop?: Maybe<Game_Progress_Snapshot_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Game_Progress_Snapshot_Stddev_Samp_Fields>;
  sum?: Maybe<Game_Progress_Snapshot_Sum_Fields>;
  var_pop?: Maybe<Game_Progress_Snapshot_Var_Pop_Fields>;
  var_samp?: Maybe<Game_Progress_Snapshot_Var_Samp_Fields>;
  variance?: Maybe<Game_Progress_Snapshot_Variance_Fields>;
};


/** aggregate fields of "game_progress_snapshot" */
export type Game_Progress_Snapshot_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Game_Progress_Snapshot_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Game_Progress_Snapshot_Avg_Fields = {
  __typename?: 'game_progress_snapshot_avg_fields';
  kol_id?: Maybe<Scalars['Float']>;
  lap_count?: Maybe<Scalars['Float']>;
  lap_progress?: Maybe<Scalars['Float']>;
  lap_progress_km?: Maybe<Scalars['Float']>;
  order_count?: Maybe<Scalars['Float']>;
  order_increment?: Maybe<Scalars['Float']>;
  order_volume?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  prev_lap_count?: Maybe<Scalars['Float']>;
  prev_lap_progress?: Maybe<Scalars['Float']>;
  prev_lap_progress_km?: Maybe<Scalars['Float']>;
  prev_order_count?: Maybe<Scalars['Float']>;
  prev_order_increment?: Maybe<Scalars['Float']>;
  prev_total_progress?: Maybe<Scalars['Float']>;
  total_progress?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "game_progress_snapshot". All fields are combined with a logical 'AND'. */
export type Game_Progress_Snapshot_Bool_Exp = {
  _and?: InputMaybe<Array<Game_Progress_Snapshot_Bool_Exp>>;
  _not?: InputMaybe<Game_Progress_Snapshot_Bool_Exp>;
  _or?: InputMaybe<Array<Game_Progress_Snapshot_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  gender?: InputMaybe<String_Comparison_Exp>;
  kol_id?: InputMaybe<Int_Comparison_Exp>;
  lap_count?: InputMaybe<Int_Comparison_Exp>;
  lap_progress?: InputMaybe<Int_Comparison_Exp>;
  lap_progress_km?: InputMaybe<Numeric_Comparison_Exp>;
  ledger?: InputMaybe<String_Comparison_Exp>;
  mobile_number?: InputMaybe<String_Comparison_Exp>;
  mobile_number_redacted?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order_count?: InputMaybe<Int_Comparison_Exp>;
  order_id?: InputMaybe<String_Comparison_Exp>;
  order_increment?: InputMaybe<Int_Comparison_Exp>;
  order_kind?: InputMaybe<String_Comparison_Exp>;
  order_product?: InputMaybe<String_Comparison_Exp>;
  order_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  order_volume?: InputMaybe<Numeric_Comparison_Exp>;
  position?: InputMaybe<Int_Comparison_Exp>;
  prev_lap_count?: InputMaybe<Int_Comparison_Exp>;
  prev_lap_progress?: InputMaybe<Int_Comparison_Exp>;
  prev_lap_progress_km?: InputMaybe<Numeric_Comparison_Exp>;
  prev_order_count?: InputMaybe<Int_Comparison_Exp>;
  prev_order_id?: InputMaybe<String_Comparison_Exp>;
  prev_order_increment?: InputMaybe<Int_Comparison_Exp>;
  prev_order_kind?: InputMaybe<String_Comparison_Exp>;
  prev_order_product?: InputMaybe<String_Comparison_Exp>;
  prev_order_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  prev_total_progress?: InputMaybe<Int_Comparison_Exp>;
  snapshot_key?: InputMaybe<String_Comparison_Exp>;
  total_progress?: InputMaybe<Int_Comparison_Exp>;
  vehicle?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "game_progress_snapshot" */
export enum Game_Progress_Snapshot_Constraint {
  /** unique or primary key constraint */
  GameProgressSnapshotPkey = 'game_progress_snapshot_pkey'
}

/** input type for incrementing numeric columns in table "game_progress_snapshot" */
export type Game_Progress_Snapshot_Inc_Input = {
  kol_id?: InputMaybe<Scalars['Int']>;
  lap_count?: InputMaybe<Scalars['Int']>;
  lap_progress?: InputMaybe<Scalars['Int']>;
  lap_progress_km?: InputMaybe<Scalars['numeric']>;
  order_count?: InputMaybe<Scalars['Int']>;
  order_increment?: InputMaybe<Scalars['Int']>;
  order_volume?: InputMaybe<Scalars['numeric']>;
  position?: InputMaybe<Scalars['Int']>;
  prev_lap_count?: InputMaybe<Scalars['Int']>;
  prev_lap_progress?: InputMaybe<Scalars['Int']>;
  prev_lap_progress_km?: InputMaybe<Scalars['numeric']>;
  prev_order_count?: InputMaybe<Scalars['Int']>;
  prev_order_increment?: InputMaybe<Scalars['Int']>;
  prev_total_progress?: InputMaybe<Scalars['Int']>;
  total_progress?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "game_progress_snapshot" */
export type Game_Progress_Snapshot_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  gender?: InputMaybe<Scalars['String']>;
  kol_id?: InputMaybe<Scalars['Int']>;
  lap_count?: InputMaybe<Scalars['Int']>;
  lap_progress?: InputMaybe<Scalars['Int']>;
  lap_progress_km?: InputMaybe<Scalars['numeric']>;
  ledger?: InputMaybe<Scalars['String']>;
  mobile_number?: InputMaybe<Scalars['String']>;
  mobile_number_redacted?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order_count?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['String']>;
  order_increment?: InputMaybe<Scalars['Int']>;
  order_kind?: InputMaybe<Scalars['String']>;
  order_product?: InputMaybe<Scalars['String']>;
  order_time?: InputMaybe<Scalars['timestamptz']>;
  order_volume?: InputMaybe<Scalars['numeric']>;
  position?: InputMaybe<Scalars['Int']>;
  prev_lap_count?: InputMaybe<Scalars['Int']>;
  prev_lap_progress?: InputMaybe<Scalars['Int']>;
  prev_lap_progress_km?: InputMaybe<Scalars['numeric']>;
  prev_order_count?: InputMaybe<Scalars['Int']>;
  prev_order_id?: InputMaybe<Scalars['String']>;
  prev_order_increment?: InputMaybe<Scalars['Int']>;
  prev_order_kind?: InputMaybe<Scalars['String']>;
  prev_order_product?: InputMaybe<Scalars['String']>;
  prev_order_time?: InputMaybe<Scalars['timestamptz']>;
  prev_total_progress?: InputMaybe<Scalars['Int']>;
  snapshot_key?: InputMaybe<Scalars['String']>;
  total_progress?: InputMaybe<Scalars['Int']>;
  vehicle?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Game_Progress_Snapshot_Max_Fields = {
  __typename?: 'game_progress_snapshot_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  gender?: Maybe<Scalars['String']>;
  kol_id?: Maybe<Scalars['Int']>;
  lap_count?: Maybe<Scalars['Int']>;
  lap_progress?: Maybe<Scalars['Int']>;
  lap_progress_km?: Maybe<Scalars['numeric']>;
  ledger?: Maybe<Scalars['String']>;
  mobile_number?: Maybe<Scalars['String']>;
  mobile_number_redacted?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order_count?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['String']>;
  order_increment?: Maybe<Scalars['Int']>;
  order_kind?: Maybe<Scalars['String']>;
  order_product?: Maybe<Scalars['String']>;
  order_time?: Maybe<Scalars['timestamptz']>;
  order_volume?: Maybe<Scalars['numeric']>;
  position?: Maybe<Scalars['Int']>;
  prev_lap_count?: Maybe<Scalars['Int']>;
  prev_lap_progress?: Maybe<Scalars['Int']>;
  prev_lap_progress_km?: Maybe<Scalars['numeric']>;
  prev_order_count?: Maybe<Scalars['Int']>;
  prev_order_id?: Maybe<Scalars['String']>;
  prev_order_increment?: Maybe<Scalars['Int']>;
  prev_order_kind?: Maybe<Scalars['String']>;
  prev_order_product?: Maybe<Scalars['String']>;
  prev_order_time?: Maybe<Scalars['timestamptz']>;
  prev_total_progress?: Maybe<Scalars['Int']>;
  snapshot_key?: Maybe<Scalars['String']>;
  total_progress?: Maybe<Scalars['Int']>;
  vehicle?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Game_Progress_Snapshot_Min_Fields = {
  __typename?: 'game_progress_snapshot_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  gender?: Maybe<Scalars['String']>;
  kol_id?: Maybe<Scalars['Int']>;
  lap_count?: Maybe<Scalars['Int']>;
  lap_progress?: Maybe<Scalars['Int']>;
  lap_progress_km?: Maybe<Scalars['numeric']>;
  ledger?: Maybe<Scalars['String']>;
  mobile_number?: Maybe<Scalars['String']>;
  mobile_number_redacted?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order_count?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['String']>;
  order_increment?: Maybe<Scalars['Int']>;
  order_kind?: Maybe<Scalars['String']>;
  order_product?: Maybe<Scalars['String']>;
  order_time?: Maybe<Scalars['timestamptz']>;
  order_volume?: Maybe<Scalars['numeric']>;
  position?: Maybe<Scalars['Int']>;
  prev_lap_count?: Maybe<Scalars['Int']>;
  prev_lap_progress?: Maybe<Scalars['Int']>;
  prev_lap_progress_km?: Maybe<Scalars['numeric']>;
  prev_order_count?: Maybe<Scalars['Int']>;
  prev_order_id?: Maybe<Scalars['String']>;
  prev_order_increment?: Maybe<Scalars['Int']>;
  prev_order_kind?: Maybe<Scalars['String']>;
  prev_order_product?: Maybe<Scalars['String']>;
  prev_order_time?: Maybe<Scalars['timestamptz']>;
  prev_total_progress?: Maybe<Scalars['Int']>;
  snapshot_key?: Maybe<Scalars['String']>;
  total_progress?: Maybe<Scalars['Int']>;
  vehicle?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "game_progress_snapshot" */
export type Game_Progress_Snapshot_Mutation_Response = {
  __typename?: 'game_progress_snapshot_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Game_Progress_Snapshot>;
};

/** on conflict condition type for table "game_progress_snapshot" */
export type Game_Progress_Snapshot_On_Conflict = {
  constraint: Game_Progress_Snapshot_Constraint;
  update_columns?: Array<Game_Progress_Snapshot_Update_Column>;
  where?: InputMaybe<Game_Progress_Snapshot_Bool_Exp>;
};

/** Ordering options when selecting data from "game_progress_snapshot". */
export type Game_Progress_Snapshot_Order_By = {
  created_at?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  kol_id?: InputMaybe<Order_By>;
  lap_count?: InputMaybe<Order_By>;
  lap_progress?: InputMaybe<Order_By>;
  lap_progress_km?: InputMaybe<Order_By>;
  ledger?: InputMaybe<Order_By>;
  mobile_number?: InputMaybe<Order_By>;
  mobile_number_redacted?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order_count?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  order_increment?: InputMaybe<Order_By>;
  order_kind?: InputMaybe<Order_By>;
  order_product?: InputMaybe<Order_By>;
  order_time?: InputMaybe<Order_By>;
  order_volume?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  prev_lap_count?: InputMaybe<Order_By>;
  prev_lap_progress?: InputMaybe<Order_By>;
  prev_lap_progress_km?: InputMaybe<Order_By>;
  prev_order_count?: InputMaybe<Order_By>;
  prev_order_id?: InputMaybe<Order_By>;
  prev_order_increment?: InputMaybe<Order_By>;
  prev_order_kind?: InputMaybe<Order_By>;
  prev_order_product?: InputMaybe<Order_By>;
  prev_order_time?: InputMaybe<Order_By>;
  prev_total_progress?: InputMaybe<Order_By>;
  snapshot_key?: InputMaybe<Order_By>;
  total_progress?: InputMaybe<Order_By>;
  vehicle?: InputMaybe<Order_By>;
};

/** primary key columns input for table: game_progress_snapshot */
export type Game_Progress_Snapshot_Pk_Columns_Input = {
  mobile_number: Scalars['String'];
  snapshot_key: Scalars['String'];
  vehicle: Scalars['String'];
};

/** select columns of table "game_progress_snapshot" */
export enum Game_Progress_Snapshot_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Gender = 'gender',
  /** column name */
  KolId = 'kol_id',
  /** column name */
  LapCount = 'lap_count',
  /** column name */
  LapProgress = 'lap_progress',
  /** column name */
  LapProgressKm = 'lap_progress_km',
  /** column name */
  Ledger = 'ledger',
  /** column name */
  MobileNumber = 'mobile_number',
  /** column name */
  MobileNumberRedacted = 'mobile_number_redacted',
  /** column name */
  Name = 'name',
  /** column name */
  OrderCount = 'order_count',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  OrderIncrement = 'order_increment',
  /** column name */
  OrderKind = 'order_kind',
  /** column name */
  OrderProduct = 'order_product',
  /** column name */
  OrderTime = 'order_time',
  /** column name */
  OrderVolume = 'order_volume',
  /** column name */
  Position = 'position',
  /** column name */
  PrevLapCount = 'prev_lap_count',
  /** column name */
  PrevLapProgress = 'prev_lap_progress',
  /** column name */
  PrevLapProgressKm = 'prev_lap_progress_km',
  /** column name */
  PrevOrderCount = 'prev_order_count',
  /** column name */
  PrevOrderId = 'prev_order_id',
  /** column name */
  PrevOrderIncrement = 'prev_order_increment',
  /** column name */
  PrevOrderKind = 'prev_order_kind',
  /** column name */
  PrevOrderProduct = 'prev_order_product',
  /** column name */
  PrevOrderTime = 'prev_order_time',
  /** column name */
  PrevTotalProgress = 'prev_total_progress',
  /** column name */
  SnapshotKey = 'snapshot_key',
  /** column name */
  TotalProgress = 'total_progress',
  /** column name */
  Vehicle = 'vehicle'
}

/** input type for updating data in table "game_progress_snapshot" */
export type Game_Progress_Snapshot_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  gender?: InputMaybe<Scalars['String']>;
  kol_id?: InputMaybe<Scalars['Int']>;
  lap_count?: InputMaybe<Scalars['Int']>;
  lap_progress?: InputMaybe<Scalars['Int']>;
  lap_progress_km?: InputMaybe<Scalars['numeric']>;
  ledger?: InputMaybe<Scalars['String']>;
  mobile_number?: InputMaybe<Scalars['String']>;
  mobile_number_redacted?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order_count?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['String']>;
  order_increment?: InputMaybe<Scalars['Int']>;
  order_kind?: InputMaybe<Scalars['String']>;
  order_product?: InputMaybe<Scalars['String']>;
  order_time?: InputMaybe<Scalars['timestamptz']>;
  order_volume?: InputMaybe<Scalars['numeric']>;
  position?: InputMaybe<Scalars['Int']>;
  prev_lap_count?: InputMaybe<Scalars['Int']>;
  prev_lap_progress?: InputMaybe<Scalars['Int']>;
  prev_lap_progress_km?: InputMaybe<Scalars['numeric']>;
  prev_order_count?: InputMaybe<Scalars['Int']>;
  prev_order_id?: InputMaybe<Scalars['String']>;
  prev_order_increment?: InputMaybe<Scalars['Int']>;
  prev_order_kind?: InputMaybe<Scalars['String']>;
  prev_order_product?: InputMaybe<Scalars['String']>;
  prev_order_time?: InputMaybe<Scalars['timestamptz']>;
  prev_total_progress?: InputMaybe<Scalars['Int']>;
  snapshot_key?: InputMaybe<Scalars['String']>;
  total_progress?: InputMaybe<Scalars['Int']>;
  vehicle?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Game_Progress_Snapshot_Stddev_Fields = {
  __typename?: 'game_progress_snapshot_stddev_fields';
  kol_id?: Maybe<Scalars['Float']>;
  lap_count?: Maybe<Scalars['Float']>;
  lap_progress?: Maybe<Scalars['Float']>;
  lap_progress_km?: Maybe<Scalars['Float']>;
  order_count?: Maybe<Scalars['Float']>;
  order_increment?: Maybe<Scalars['Float']>;
  order_volume?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  prev_lap_count?: Maybe<Scalars['Float']>;
  prev_lap_progress?: Maybe<Scalars['Float']>;
  prev_lap_progress_km?: Maybe<Scalars['Float']>;
  prev_order_count?: Maybe<Scalars['Float']>;
  prev_order_increment?: Maybe<Scalars['Float']>;
  prev_total_progress?: Maybe<Scalars['Float']>;
  total_progress?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Game_Progress_Snapshot_Stddev_Pop_Fields = {
  __typename?: 'game_progress_snapshot_stddev_pop_fields';
  kol_id?: Maybe<Scalars['Float']>;
  lap_count?: Maybe<Scalars['Float']>;
  lap_progress?: Maybe<Scalars['Float']>;
  lap_progress_km?: Maybe<Scalars['Float']>;
  order_count?: Maybe<Scalars['Float']>;
  order_increment?: Maybe<Scalars['Float']>;
  order_volume?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  prev_lap_count?: Maybe<Scalars['Float']>;
  prev_lap_progress?: Maybe<Scalars['Float']>;
  prev_lap_progress_km?: Maybe<Scalars['Float']>;
  prev_order_count?: Maybe<Scalars['Float']>;
  prev_order_increment?: Maybe<Scalars['Float']>;
  prev_total_progress?: Maybe<Scalars['Float']>;
  total_progress?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Game_Progress_Snapshot_Stddev_Samp_Fields = {
  __typename?: 'game_progress_snapshot_stddev_samp_fields';
  kol_id?: Maybe<Scalars['Float']>;
  lap_count?: Maybe<Scalars['Float']>;
  lap_progress?: Maybe<Scalars['Float']>;
  lap_progress_km?: Maybe<Scalars['Float']>;
  order_count?: Maybe<Scalars['Float']>;
  order_increment?: Maybe<Scalars['Float']>;
  order_volume?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  prev_lap_count?: Maybe<Scalars['Float']>;
  prev_lap_progress?: Maybe<Scalars['Float']>;
  prev_lap_progress_km?: Maybe<Scalars['Float']>;
  prev_order_count?: Maybe<Scalars['Float']>;
  prev_order_increment?: Maybe<Scalars['Float']>;
  prev_total_progress?: Maybe<Scalars['Float']>;
  total_progress?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Game_Progress_Snapshot_Sum_Fields = {
  __typename?: 'game_progress_snapshot_sum_fields';
  kol_id?: Maybe<Scalars['Int']>;
  lap_count?: Maybe<Scalars['Int']>;
  lap_progress?: Maybe<Scalars['Int']>;
  lap_progress_km?: Maybe<Scalars['numeric']>;
  order_count?: Maybe<Scalars['Int']>;
  order_increment?: Maybe<Scalars['Int']>;
  order_volume?: Maybe<Scalars['numeric']>;
  position?: Maybe<Scalars['Int']>;
  prev_lap_count?: Maybe<Scalars['Int']>;
  prev_lap_progress?: Maybe<Scalars['Int']>;
  prev_lap_progress_km?: Maybe<Scalars['numeric']>;
  prev_order_count?: Maybe<Scalars['Int']>;
  prev_order_increment?: Maybe<Scalars['Int']>;
  prev_total_progress?: Maybe<Scalars['Int']>;
  total_progress?: Maybe<Scalars['Int']>;
};

/** update columns of table "game_progress_snapshot" */
export enum Game_Progress_Snapshot_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Gender = 'gender',
  /** column name */
  KolId = 'kol_id',
  /** column name */
  LapCount = 'lap_count',
  /** column name */
  LapProgress = 'lap_progress',
  /** column name */
  LapProgressKm = 'lap_progress_km',
  /** column name */
  Ledger = 'ledger',
  /** column name */
  MobileNumber = 'mobile_number',
  /** column name */
  MobileNumberRedacted = 'mobile_number_redacted',
  /** column name */
  Name = 'name',
  /** column name */
  OrderCount = 'order_count',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  OrderIncrement = 'order_increment',
  /** column name */
  OrderKind = 'order_kind',
  /** column name */
  OrderProduct = 'order_product',
  /** column name */
  OrderTime = 'order_time',
  /** column name */
  OrderVolume = 'order_volume',
  /** column name */
  Position = 'position',
  /** column name */
  PrevLapCount = 'prev_lap_count',
  /** column name */
  PrevLapProgress = 'prev_lap_progress',
  /** column name */
  PrevLapProgressKm = 'prev_lap_progress_km',
  /** column name */
  PrevOrderCount = 'prev_order_count',
  /** column name */
  PrevOrderId = 'prev_order_id',
  /** column name */
  PrevOrderIncrement = 'prev_order_increment',
  /** column name */
  PrevOrderKind = 'prev_order_kind',
  /** column name */
  PrevOrderProduct = 'prev_order_product',
  /** column name */
  PrevOrderTime = 'prev_order_time',
  /** column name */
  PrevTotalProgress = 'prev_total_progress',
  /** column name */
  SnapshotKey = 'snapshot_key',
  /** column name */
  TotalProgress = 'total_progress',
  /** column name */
  Vehicle = 'vehicle'
}

/** aggregate var_pop on columns */
export type Game_Progress_Snapshot_Var_Pop_Fields = {
  __typename?: 'game_progress_snapshot_var_pop_fields';
  kol_id?: Maybe<Scalars['Float']>;
  lap_count?: Maybe<Scalars['Float']>;
  lap_progress?: Maybe<Scalars['Float']>;
  lap_progress_km?: Maybe<Scalars['Float']>;
  order_count?: Maybe<Scalars['Float']>;
  order_increment?: Maybe<Scalars['Float']>;
  order_volume?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  prev_lap_count?: Maybe<Scalars['Float']>;
  prev_lap_progress?: Maybe<Scalars['Float']>;
  prev_lap_progress_km?: Maybe<Scalars['Float']>;
  prev_order_count?: Maybe<Scalars['Float']>;
  prev_order_increment?: Maybe<Scalars['Float']>;
  prev_total_progress?: Maybe<Scalars['Float']>;
  total_progress?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Game_Progress_Snapshot_Var_Samp_Fields = {
  __typename?: 'game_progress_snapshot_var_samp_fields';
  kol_id?: Maybe<Scalars['Float']>;
  lap_count?: Maybe<Scalars['Float']>;
  lap_progress?: Maybe<Scalars['Float']>;
  lap_progress_km?: Maybe<Scalars['Float']>;
  order_count?: Maybe<Scalars['Float']>;
  order_increment?: Maybe<Scalars['Float']>;
  order_volume?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  prev_lap_count?: Maybe<Scalars['Float']>;
  prev_lap_progress?: Maybe<Scalars['Float']>;
  prev_lap_progress_km?: Maybe<Scalars['Float']>;
  prev_order_count?: Maybe<Scalars['Float']>;
  prev_order_increment?: Maybe<Scalars['Float']>;
  prev_total_progress?: Maybe<Scalars['Float']>;
  total_progress?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Game_Progress_Snapshot_Variance_Fields = {
  __typename?: 'game_progress_snapshot_variance_fields';
  kol_id?: Maybe<Scalars['Float']>;
  lap_count?: Maybe<Scalars['Float']>;
  lap_progress?: Maybe<Scalars['Float']>;
  lap_progress_km?: Maybe<Scalars['Float']>;
  order_count?: Maybe<Scalars['Float']>;
  order_increment?: Maybe<Scalars['Float']>;
  order_volume?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  prev_lap_count?: Maybe<Scalars['Float']>;
  prev_lap_progress?: Maybe<Scalars['Float']>;
  prev_lap_progress_km?: Maybe<Scalars['Float']>;
  prev_order_count?: Maybe<Scalars['Float']>;
  prev_order_increment?: Maybe<Scalars['Float']>;
  prev_total_progress?: Maybe<Scalars['Float']>;
  total_progress?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "game_state" */
export type Game_State = {
  __typename?: 'game_state';
  id: Scalars['Int'];
  last_trx_date?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "game_state" */
export type Game_State_Aggregate = {
  __typename?: 'game_state_aggregate';
  aggregate?: Maybe<Game_State_Aggregate_Fields>;
  nodes: Array<Game_State>;
};

/** aggregate fields of "game_state" */
export type Game_State_Aggregate_Fields = {
  __typename?: 'game_state_aggregate_fields';
  avg?: Maybe<Game_State_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Game_State_Max_Fields>;
  min?: Maybe<Game_State_Min_Fields>;
  stddev?: Maybe<Game_State_Stddev_Fields>;
  stddev_pop?: Maybe<Game_State_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Game_State_Stddev_Samp_Fields>;
  sum?: Maybe<Game_State_Sum_Fields>;
  var_pop?: Maybe<Game_State_Var_Pop_Fields>;
  var_samp?: Maybe<Game_State_Var_Samp_Fields>;
  variance?: Maybe<Game_State_Variance_Fields>;
};


/** aggregate fields of "game_state" */
export type Game_State_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Game_State_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Game_State_Avg_Fields = {
  __typename?: 'game_state_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "game_state". All fields are combined with a logical 'AND'. */
export type Game_State_Bool_Exp = {
  _and?: InputMaybe<Array<Game_State_Bool_Exp>>;
  _not?: InputMaybe<Game_State_Bool_Exp>;
  _or?: InputMaybe<Array<Game_State_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  last_trx_date?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "game_state" */
export enum Game_State_Constraint {
  /** unique or primary key constraint */
  GameStatePkey = 'game_state_pkey'
}

/** input type for incrementing numeric columns in table "game_state" */
export type Game_State_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "game_state" */
export type Game_State_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  last_trx_date?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Game_State_Max_Fields = {
  __typename?: 'game_state_max_fields';
  id?: Maybe<Scalars['Int']>;
  last_trx_date?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Game_State_Min_Fields = {
  __typename?: 'game_state_min_fields';
  id?: Maybe<Scalars['Int']>;
  last_trx_date?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "game_state" */
export type Game_State_Mutation_Response = {
  __typename?: 'game_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Game_State>;
};

/** on conflict condition type for table "game_state" */
export type Game_State_On_Conflict = {
  constraint: Game_State_Constraint;
  update_columns?: Array<Game_State_Update_Column>;
  where?: InputMaybe<Game_State_Bool_Exp>;
};

/** Ordering options when selecting data from "game_state". */
export type Game_State_Order_By = {
  id?: InputMaybe<Order_By>;
  last_trx_date?: InputMaybe<Order_By>;
};

/** primary key columns input for table: game_state */
export type Game_State_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "game_state" */
export enum Game_State_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LastTrxDate = 'last_trx_date'
}

/** input type for updating data in table "game_state" */
export type Game_State_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  last_trx_date?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Game_State_Stddev_Fields = {
  __typename?: 'game_state_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Game_State_Stddev_Pop_Fields = {
  __typename?: 'game_state_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Game_State_Stddev_Samp_Fields = {
  __typename?: 'game_state_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Game_State_Sum_Fields = {
  __typename?: 'game_state_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "game_state" */
export enum Game_State_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LastTrxDate = 'last_trx_date'
}

/** aggregate var_pop on columns */
export type Game_State_Var_Pop_Fields = {
  __typename?: 'game_state_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Game_State_Var_Samp_Fields = {
  __typename?: 'game_state_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Game_State_Variance_Fields = {
  __typename?: 'game_state_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Get_Leaderboard_Args = {
  by_mobile_number?: InputMaybe<Scalars['String']>;
  by_vehicle?: InputMaybe<Scalars['String']>;
};

export type Get_User_Progress_Args = {
  by_mobile_number?: InputMaybe<Scalars['String']>;
  by_vehicle?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** execute VOLATILE function "choose_kol" which returns "game_progress_live" */
  choose_kol: Array<Game_Progress_Live>;
  /** execute VOLATILE function "choose_kol2" which returns "response_wrapper" */
  choose_kol2: Array<Response_Wrapper>;
  /** execute VOLATILE function "create_game_progress_snapshot" which returns "response_wrapper" */
  create_game_progress_snapshot: Array<Response_Wrapper>;
  /** delete data from the table: "batch_winner" */
  delete_batch_winner?: Maybe<Batch_Winner_Mutation_Response>;
  /** delete single row from the table: "batch_winner" */
  delete_batch_winner_by_pk?: Maybe<Batch_Winner>;
  /** delete data from the table: "game_progress_live" */
  delete_game_progress_live?: Maybe<Game_Progress_Live_Mutation_Response>;
  /** delete single row from the table: "game_progress_live" */
  delete_game_progress_live_by_pk?: Maybe<Game_Progress_Live>;
  /** delete data from the table: "game_progress_live_compact" */
  delete_game_progress_live_compact?: Maybe<Game_Progress_Live_Compact_Mutation_Response>;
  /** delete data from the table: "game_progress_snapshot" */
  delete_game_progress_snapshot?: Maybe<Game_Progress_Snapshot_Mutation_Response>;
  /** delete single row from the table: "game_progress_snapshot" */
  delete_game_progress_snapshot_by_pk?: Maybe<Game_Progress_Snapshot>;
  /** delete data from the table: "game_state" */
  delete_game_state?: Maybe<Game_State_Mutation_Response>;
  /** delete single row from the table: "game_state" */
  delete_game_state_by_pk?: Maybe<Game_State>;
  /** delete data from the table: "mypertamina_fuel_source" */
  delete_mypertamina_fuel_source?: Maybe<Mypertamina_Fuel_Source_Mutation_Response>;
  /** delete single row from the table: "mypertamina_fuel_source" */
  delete_mypertamina_fuel_source_by_pk?: Maybe<Mypertamina_Fuel_Source>;
  /** delete data from the table: "user_info" */
  delete_user_info?: Maybe<User_Info_Mutation_Response>;
  /** delete single row from the table: "user_info" */
  delete_user_info_by_pk?: Maybe<User_Info>;
  /** execute VOLATILE function "get_leaderboard" which returns "game_progress_live_compact" */
  get_leaderboard: Array<Game_Progress_Live_Compact>;
  /** execute VOLATILE function "get_user_progress" which returns "game_progress_live_compact" */
  get_user_progress: Array<Game_Progress_Live_Compact>;
  /** insert data into the table: "batch_winner" */
  insert_batch_winner?: Maybe<Batch_Winner_Mutation_Response>;
  /** insert a single row into the table: "batch_winner" */
  insert_batch_winner_one?: Maybe<Batch_Winner>;
  /** insert data into the table: "game_progress_live" */
  insert_game_progress_live?: Maybe<Game_Progress_Live_Mutation_Response>;
  /** insert data into the table: "game_progress_live_compact" */
  insert_game_progress_live_compact?: Maybe<Game_Progress_Live_Compact_Mutation_Response>;
  /** insert a single row into the table: "game_progress_live_compact" */
  insert_game_progress_live_compact_one?: Maybe<Game_Progress_Live_Compact>;
  /** insert a single row into the table: "game_progress_live" */
  insert_game_progress_live_one?: Maybe<Game_Progress_Live>;
  /** insert data into the table: "game_progress_snapshot" */
  insert_game_progress_snapshot?: Maybe<Game_Progress_Snapshot_Mutation_Response>;
  /** insert a single row into the table: "game_progress_snapshot" */
  insert_game_progress_snapshot_one?: Maybe<Game_Progress_Snapshot>;
  /** insert data into the table: "game_state" */
  insert_game_state?: Maybe<Game_State_Mutation_Response>;
  /** insert a single row into the table: "game_state" */
  insert_game_state_one?: Maybe<Game_State>;
  /** insert data into the table: "mypertamina_fuel_source" */
  insert_mypertamina_fuel_source?: Maybe<Mypertamina_Fuel_Source_Mutation_Response>;
  /** insert a single row into the table: "mypertamina_fuel_source" */
  insert_mypertamina_fuel_source_one?: Maybe<Mypertamina_Fuel_Source>;
  /** insert data into the table: "user_info" */
  insert_user_info?: Maybe<User_Info_Mutation_Response>;
  /** insert a single row into the table: "user_info" */
  insert_user_info_one?: Maybe<User_Info>;
  /** execute VOLATILE function "rerank_vehicle" which returns "game_progress_live" */
  rerank_vehicle: Array<Game_Progress_Live>;
  /** execute VOLATILE function "softroll_game_progress" which returns "response_wrapper" */
  softroll_game_progress: Array<Response_Wrapper>;
  /** update data of the table: "batch_winner" */
  update_batch_winner?: Maybe<Batch_Winner_Mutation_Response>;
  /** update single row of the table: "batch_winner" */
  update_batch_winner_by_pk?: Maybe<Batch_Winner>;
  /** update data of the table: "game_progress_live" */
  update_game_progress_live?: Maybe<Game_Progress_Live_Mutation_Response>;
  /** update single row of the table: "game_progress_live" */
  update_game_progress_live_by_pk?: Maybe<Game_Progress_Live>;
  /** update data of the table: "game_progress_live_compact" */
  update_game_progress_live_compact?: Maybe<Game_Progress_Live_Compact_Mutation_Response>;
  /** update data of the table: "game_progress_snapshot" */
  update_game_progress_snapshot?: Maybe<Game_Progress_Snapshot_Mutation_Response>;
  /** update single row of the table: "game_progress_snapshot" */
  update_game_progress_snapshot_by_pk?: Maybe<Game_Progress_Snapshot>;
  /** update data of the table: "game_state" */
  update_game_state?: Maybe<Game_State_Mutation_Response>;
  /** update single row of the table: "game_state" */
  update_game_state_by_pk?: Maybe<Game_State>;
  /** update data of the table: "mypertamina_fuel_source" */
  update_mypertamina_fuel_source?: Maybe<Mypertamina_Fuel_Source_Mutation_Response>;
  /** update single row of the table: "mypertamina_fuel_source" */
  update_mypertamina_fuel_source_by_pk?: Maybe<Mypertamina_Fuel_Source>;
  /** update data of the table: "user_info" */
  update_user_info?: Maybe<User_Info_Mutation_Response>;
  /** update single row of the table: "user_info" */
  update_user_info_by_pk?: Maybe<User_Info>;
  /** execute VOLATILE function "upsert_user_info" which returns "user_info" */
  upsert_user_info: Array<User_Info>;
};


/** mutation root */
export type Mutation_RootChoose_KolArgs = {
  args: Choose_Kol_Args;
  distinct_on?: InputMaybe<Array<Game_Progress_Live_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Progress_Live_Order_By>>;
  where?: InputMaybe<Game_Progress_Live_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootChoose_Kol2Args = {
  args: Choose_Kol2_Args;
  distinct_on?: InputMaybe<Array<Response_Wrapper_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Response_Wrapper_Order_By>>;
  where?: InputMaybe<Response_Wrapper_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootCreate_Game_Progress_SnapshotArgs = {
  args: Create_Game_Progress_Snapshot_Args;
  distinct_on?: InputMaybe<Array<Response_Wrapper_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Response_Wrapper_Order_By>>;
  where?: InputMaybe<Response_Wrapper_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootDelete_Batch_WinnerArgs = {
  where: Batch_Winner_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Batch_Winner_By_PkArgs = {
  batch_num: Scalars['Int'];
  position: Scalars['Int'];
  vehicle: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Game_Progress_LiveArgs = {
  where: Game_Progress_Live_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Game_Progress_Live_By_PkArgs = {
  mobile_number: Scalars['String'];
  vehicle: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Game_Progress_Live_CompactArgs = {
  where: Game_Progress_Live_Compact_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Game_Progress_SnapshotArgs = {
  where: Game_Progress_Snapshot_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Game_Progress_Snapshot_By_PkArgs = {
  mobile_number: Scalars['String'];
  snapshot_key: Scalars['String'];
  vehicle: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Game_StateArgs = {
  where: Game_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Game_State_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Mypertamina_Fuel_SourceArgs = {
  where: Mypertamina_Fuel_Source_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mypertamina_Fuel_Source_By_PkArgs = {
  trx_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_User_InfoArgs = {
  where: User_Info_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Info_By_PkArgs = {
  mobile_number: Scalars['String'];
};


/** mutation root */
export type Mutation_RootGet_LeaderboardArgs = {
  args: Get_Leaderboard_Args;
  distinct_on?: InputMaybe<Array<Game_Progress_Live_Compact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Progress_Live_Compact_Order_By>>;
  where?: InputMaybe<Game_Progress_Live_Compact_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootGet_User_ProgressArgs = {
  args: Get_User_Progress_Args;
  distinct_on?: InputMaybe<Array<Game_Progress_Live_Compact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Progress_Live_Compact_Order_By>>;
  where?: InputMaybe<Game_Progress_Live_Compact_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootInsert_Batch_WinnerArgs = {
  objects: Array<Batch_Winner_Insert_Input>;
  on_conflict?: InputMaybe<Batch_Winner_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Batch_Winner_OneArgs = {
  object: Batch_Winner_Insert_Input;
  on_conflict?: InputMaybe<Batch_Winner_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Game_Progress_LiveArgs = {
  objects: Array<Game_Progress_Live_Insert_Input>;
  on_conflict?: InputMaybe<Game_Progress_Live_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Game_Progress_Live_CompactArgs = {
  objects: Array<Game_Progress_Live_Compact_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Game_Progress_Live_Compact_OneArgs = {
  object: Game_Progress_Live_Compact_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Game_Progress_Live_OneArgs = {
  object: Game_Progress_Live_Insert_Input;
  on_conflict?: InputMaybe<Game_Progress_Live_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Game_Progress_SnapshotArgs = {
  objects: Array<Game_Progress_Snapshot_Insert_Input>;
  on_conflict?: InputMaybe<Game_Progress_Snapshot_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Game_Progress_Snapshot_OneArgs = {
  object: Game_Progress_Snapshot_Insert_Input;
  on_conflict?: InputMaybe<Game_Progress_Snapshot_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Game_StateArgs = {
  objects: Array<Game_State_Insert_Input>;
  on_conflict?: InputMaybe<Game_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Game_State_OneArgs = {
  object: Game_State_Insert_Input;
  on_conflict?: InputMaybe<Game_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mypertamina_Fuel_SourceArgs = {
  objects: Array<Mypertamina_Fuel_Source_Insert_Input>;
  on_conflict?: InputMaybe<Mypertamina_Fuel_Source_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mypertamina_Fuel_Source_OneArgs = {
  object: Mypertamina_Fuel_Source_Insert_Input;
  on_conflict?: InputMaybe<Mypertamina_Fuel_Source_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_InfoArgs = {
  objects: Array<User_Info_Insert_Input>;
  on_conflict?: InputMaybe<User_Info_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Info_OneArgs = {
  object: User_Info_Insert_Input;
  on_conflict?: InputMaybe<User_Info_On_Conflict>;
};


/** mutation root */
export type Mutation_RootRerank_VehicleArgs = {
  args: Rerank_Vehicle_Args;
  distinct_on?: InputMaybe<Array<Game_Progress_Live_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Progress_Live_Order_By>>;
  where?: InputMaybe<Game_Progress_Live_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootSoftroll_Game_ProgressArgs = {
  args: Softroll_Game_Progress_Args;
  distinct_on?: InputMaybe<Array<Response_Wrapper_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Response_Wrapper_Order_By>>;
  where?: InputMaybe<Response_Wrapper_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootUpdate_Batch_WinnerArgs = {
  _inc?: InputMaybe<Batch_Winner_Inc_Input>;
  _set?: InputMaybe<Batch_Winner_Set_Input>;
  where: Batch_Winner_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Batch_Winner_By_PkArgs = {
  _inc?: InputMaybe<Batch_Winner_Inc_Input>;
  _set?: InputMaybe<Batch_Winner_Set_Input>;
  pk_columns: Batch_Winner_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Game_Progress_LiveArgs = {
  _inc?: InputMaybe<Game_Progress_Live_Inc_Input>;
  _set?: InputMaybe<Game_Progress_Live_Set_Input>;
  where: Game_Progress_Live_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Game_Progress_Live_By_PkArgs = {
  _inc?: InputMaybe<Game_Progress_Live_Inc_Input>;
  _set?: InputMaybe<Game_Progress_Live_Set_Input>;
  pk_columns: Game_Progress_Live_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Game_Progress_Live_CompactArgs = {
  _inc?: InputMaybe<Game_Progress_Live_Compact_Inc_Input>;
  _set?: InputMaybe<Game_Progress_Live_Compact_Set_Input>;
  where: Game_Progress_Live_Compact_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Game_Progress_SnapshotArgs = {
  _inc?: InputMaybe<Game_Progress_Snapshot_Inc_Input>;
  _set?: InputMaybe<Game_Progress_Snapshot_Set_Input>;
  where: Game_Progress_Snapshot_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Game_Progress_Snapshot_By_PkArgs = {
  _inc?: InputMaybe<Game_Progress_Snapshot_Inc_Input>;
  _set?: InputMaybe<Game_Progress_Snapshot_Set_Input>;
  pk_columns: Game_Progress_Snapshot_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Game_StateArgs = {
  _inc?: InputMaybe<Game_State_Inc_Input>;
  _set?: InputMaybe<Game_State_Set_Input>;
  where: Game_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Game_State_By_PkArgs = {
  _inc?: InputMaybe<Game_State_Inc_Input>;
  _set?: InputMaybe<Game_State_Set_Input>;
  pk_columns: Game_State_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mypertamina_Fuel_SourceArgs = {
  _inc?: InputMaybe<Mypertamina_Fuel_Source_Inc_Input>;
  _set?: InputMaybe<Mypertamina_Fuel_Source_Set_Input>;
  where: Mypertamina_Fuel_Source_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mypertamina_Fuel_Source_By_PkArgs = {
  _inc?: InputMaybe<Mypertamina_Fuel_Source_Inc_Input>;
  _set?: InputMaybe<Mypertamina_Fuel_Source_Set_Input>;
  pk_columns: Mypertamina_Fuel_Source_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_InfoArgs = {
  _inc?: InputMaybe<User_Info_Inc_Input>;
  _set?: InputMaybe<User_Info_Set_Input>;
  where: User_Info_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Info_By_PkArgs = {
  _inc?: InputMaybe<User_Info_Inc_Input>;
  _set?: InputMaybe<User_Info_Set_Input>;
  pk_columns: User_Info_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpsert_User_InfoArgs = {
  args: Upsert_User_Info_Args;
  distinct_on?: InputMaybe<Array<User_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Info_Order_By>>;
  where?: InputMaybe<User_Info_Bool_Exp>;
};

/** columns and relationships of "mypertamina_fuel_source" */
export type Mypertamina_Fuel_Source = {
  __typename?: 'mypertamina_fuel_source';
  agent_number: Scalars['String'];
  amount: Scalars['numeric'];
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dob?: Maybe<Scalars['date']>;
  gender?: Maybe<Scalars['String']>;
  msisdn: Scalars['String'];
  name: Scalars['String'];
  process_result?: Maybe<Scalars['String']>;
  processed_at?: Maybe<Scalars['timestamptz']>;
  product: Scalars['String'];
  total_vehicles?: Maybe<Scalars['Int']>;
  trx_date: Scalars['timestamptz'];
  trx_id: Scalars['String'];
  volume: Scalars['numeric'];
};

/** aggregated selection of "mypertamina_fuel_source" */
export type Mypertamina_Fuel_Source_Aggregate = {
  __typename?: 'mypertamina_fuel_source_aggregate';
  aggregate?: Maybe<Mypertamina_Fuel_Source_Aggregate_Fields>;
  nodes: Array<Mypertamina_Fuel_Source>;
};

/** aggregate fields of "mypertamina_fuel_source" */
export type Mypertamina_Fuel_Source_Aggregate_Fields = {
  __typename?: 'mypertamina_fuel_source_aggregate_fields';
  avg?: Maybe<Mypertamina_Fuel_Source_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Mypertamina_Fuel_Source_Max_Fields>;
  min?: Maybe<Mypertamina_Fuel_Source_Min_Fields>;
  stddev?: Maybe<Mypertamina_Fuel_Source_Stddev_Fields>;
  stddev_pop?: Maybe<Mypertamina_Fuel_Source_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mypertamina_Fuel_Source_Stddev_Samp_Fields>;
  sum?: Maybe<Mypertamina_Fuel_Source_Sum_Fields>;
  var_pop?: Maybe<Mypertamina_Fuel_Source_Var_Pop_Fields>;
  var_samp?: Maybe<Mypertamina_Fuel_Source_Var_Samp_Fields>;
  variance?: Maybe<Mypertamina_Fuel_Source_Variance_Fields>;
};


/** aggregate fields of "mypertamina_fuel_source" */
export type Mypertamina_Fuel_Source_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mypertamina_Fuel_Source_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Mypertamina_Fuel_Source_Avg_Fields = {
  __typename?: 'mypertamina_fuel_source_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  total_vehicles?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "mypertamina_fuel_source". All fields are combined with a logical 'AND'. */
export type Mypertamina_Fuel_Source_Bool_Exp = {
  _and?: InputMaybe<Array<Mypertamina_Fuel_Source_Bool_Exp>>;
  _not?: InputMaybe<Mypertamina_Fuel_Source_Bool_Exp>;
  _or?: InputMaybe<Array<Mypertamina_Fuel_Source_Bool_Exp>>;
  agent_number?: InputMaybe<String_Comparison_Exp>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  dob?: InputMaybe<Date_Comparison_Exp>;
  gender?: InputMaybe<String_Comparison_Exp>;
  msisdn?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  process_result?: InputMaybe<String_Comparison_Exp>;
  processed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  product?: InputMaybe<String_Comparison_Exp>;
  total_vehicles?: InputMaybe<Int_Comparison_Exp>;
  trx_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  trx_id?: InputMaybe<String_Comparison_Exp>;
  volume?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "mypertamina_fuel_source" */
export enum Mypertamina_Fuel_Source_Constraint {
  /** unique or primary key constraint */
  MypertaminaFuelSourcePkey = 'mypertamina_fuel_source_pkey'
}

/** input type for incrementing numeric columns in table "mypertamina_fuel_source" */
export type Mypertamina_Fuel_Source_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  total_vehicles?: InputMaybe<Scalars['Int']>;
  volume?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "mypertamina_fuel_source" */
export type Mypertamina_Fuel_Source_Insert_Input = {
  agent_number?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['numeric']>;
  city?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dob?: InputMaybe<Scalars['date']>;
  gender?: InputMaybe<Scalars['String']>;
  msisdn?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  process_result?: InputMaybe<Scalars['String']>;
  processed_at?: InputMaybe<Scalars['timestamptz']>;
  product?: InputMaybe<Scalars['String']>;
  total_vehicles?: InputMaybe<Scalars['Int']>;
  trx_date?: InputMaybe<Scalars['timestamptz']>;
  trx_id?: InputMaybe<Scalars['String']>;
  volume?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Mypertamina_Fuel_Source_Max_Fields = {
  __typename?: 'mypertamina_fuel_source_max_fields';
  agent_number?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['numeric']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dob?: Maybe<Scalars['date']>;
  gender?: Maybe<Scalars['String']>;
  msisdn?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  process_result?: Maybe<Scalars['String']>;
  processed_at?: Maybe<Scalars['timestamptz']>;
  product?: Maybe<Scalars['String']>;
  total_vehicles?: Maybe<Scalars['Int']>;
  trx_date?: Maybe<Scalars['timestamptz']>;
  trx_id?: Maybe<Scalars['String']>;
  volume?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Mypertamina_Fuel_Source_Min_Fields = {
  __typename?: 'mypertamina_fuel_source_min_fields';
  agent_number?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['numeric']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dob?: Maybe<Scalars['date']>;
  gender?: Maybe<Scalars['String']>;
  msisdn?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  process_result?: Maybe<Scalars['String']>;
  processed_at?: Maybe<Scalars['timestamptz']>;
  product?: Maybe<Scalars['String']>;
  total_vehicles?: Maybe<Scalars['Int']>;
  trx_date?: Maybe<Scalars['timestamptz']>;
  trx_id?: Maybe<Scalars['String']>;
  volume?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "mypertamina_fuel_source" */
export type Mypertamina_Fuel_Source_Mutation_Response = {
  __typename?: 'mypertamina_fuel_source_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mypertamina_Fuel_Source>;
};

/** on conflict condition type for table "mypertamina_fuel_source" */
export type Mypertamina_Fuel_Source_On_Conflict = {
  constraint: Mypertamina_Fuel_Source_Constraint;
  update_columns?: Array<Mypertamina_Fuel_Source_Update_Column>;
  where?: InputMaybe<Mypertamina_Fuel_Source_Bool_Exp>;
};

/** Ordering options when selecting data from "mypertamina_fuel_source". */
export type Mypertamina_Fuel_Source_Order_By = {
  agent_number?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dob?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  msisdn?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  process_result?: InputMaybe<Order_By>;
  processed_at?: InputMaybe<Order_By>;
  product?: InputMaybe<Order_By>;
  total_vehicles?: InputMaybe<Order_By>;
  trx_date?: InputMaybe<Order_By>;
  trx_id?: InputMaybe<Order_By>;
  volume?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mypertamina_fuel_source */
export type Mypertamina_Fuel_Source_Pk_Columns_Input = {
  trx_id: Scalars['String'];
};

/** select columns of table "mypertamina_fuel_source" */
export enum Mypertamina_Fuel_Source_Select_Column {
  /** column name */
  AgentNumber = 'agent_number',
  /** column name */
  Amount = 'amount',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Dob = 'dob',
  /** column name */
  Gender = 'gender',
  /** column name */
  Msisdn = 'msisdn',
  /** column name */
  Name = 'name',
  /** column name */
  ProcessResult = 'process_result',
  /** column name */
  ProcessedAt = 'processed_at',
  /** column name */
  Product = 'product',
  /** column name */
  TotalVehicles = 'total_vehicles',
  /** column name */
  TrxDate = 'trx_date',
  /** column name */
  TrxId = 'trx_id',
  /** column name */
  Volume = 'volume'
}

/** input type for updating data in table "mypertamina_fuel_source" */
export type Mypertamina_Fuel_Source_Set_Input = {
  agent_number?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['numeric']>;
  city?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dob?: InputMaybe<Scalars['date']>;
  gender?: InputMaybe<Scalars['String']>;
  msisdn?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  process_result?: InputMaybe<Scalars['String']>;
  processed_at?: InputMaybe<Scalars['timestamptz']>;
  product?: InputMaybe<Scalars['String']>;
  total_vehicles?: InputMaybe<Scalars['Int']>;
  trx_date?: InputMaybe<Scalars['timestamptz']>;
  trx_id?: InputMaybe<Scalars['String']>;
  volume?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Mypertamina_Fuel_Source_Stddev_Fields = {
  __typename?: 'mypertamina_fuel_source_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  total_vehicles?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Mypertamina_Fuel_Source_Stddev_Pop_Fields = {
  __typename?: 'mypertamina_fuel_source_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  total_vehicles?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Mypertamina_Fuel_Source_Stddev_Samp_Fields = {
  __typename?: 'mypertamina_fuel_source_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  total_vehicles?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Mypertamina_Fuel_Source_Sum_Fields = {
  __typename?: 'mypertamina_fuel_source_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  total_vehicles?: Maybe<Scalars['Int']>;
  volume?: Maybe<Scalars['numeric']>;
};

/** update columns of table "mypertamina_fuel_source" */
export enum Mypertamina_Fuel_Source_Update_Column {
  /** column name */
  AgentNumber = 'agent_number',
  /** column name */
  Amount = 'amount',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Dob = 'dob',
  /** column name */
  Gender = 'gender',
  /** column name */
  Msisdn = 'msisdn',
  /** column name */
  Name = 'name',
  /** column name */
  ProcessResult = 'process_result',
  /** column name */
  ProcessedAt = 'processed_at',
  /** column name */
  Product = 'product',
  /** column name */
  TotalVehicles = 'total_vehicles',
  /** column name */
  TrxDate = 'trx_date',
  /** column name */
  TrxId = 'trx_id',
  /** column name */
  Volume = 'volume'
}

/** aggregate var_pop on columns */
export type Mypertamina_Fuel_Source_Var_Pop_Fields = {
  __typename?: 'mypertamina_fuel_source_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  total_vehicles?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Mypertamina_Fuel_Source_Var_Samp_Fields = {
  __typename?: 'mypertamina_fuel_source_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  total_vehicles?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Mypertamina_Fuel_Source_Variance_Fields = {
  __typename?: 'mypertamina_fuel_source_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  total_vehicles?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "batch_winner" */
  batch_winner: Array<Batch_Winner>;
  /** fetch aggregated fields from the table: "batch_winner" */
  batch_winner_aggregate: Batch_Winner_Aggregate;
  /** fetch data from the table: "batch_winner" using primary key columns */
  batch_winner_by_pk?: Maybe<Batch_Winner>;
  /** fetch data from the table: "game_progress_live" */
  game_progress_live: Array<Game_Progress_Live>;
  /** fetch aggregated fields from the table: "game_progress_live" */
  game_progress_live_aggregate: Game_Progress_Live_Aggregate;
  /** fetch data from the table: "game_progress_live" using primary key columns */
  game_progress_live_by_pk?: Maybe<Game_Progress_Live>;
  /** fetch data from the table: "game_progress_live_compact" */
  game_progress_live_compact: Array<Game_Progress_Live_Compact>;
  /** fetch aggregated fields from the table: "game_progress_live_compact" */
  game_progress_live_compact_aggregate: Game_Progress_Live_Compact_Aggregate;
  /** fetch data from the table: "game_progress_snapshot" */
  game_progress_snapshot: Array<Game_Progress_Snapshot>;
  /** fetch aggregated fields from the table: "game_progress_snapshot" */
  game_progress_snapshot_aggregate: Game_Progress_Snapshot_Aggregate;
  /** fetch data from the table: "game_progress_snapshot" using primary key columns */
  game_progress_snapshot_by_pk?: Maybe<Game_Progress_Snapshot>;
  /** fetch data from the table: "game_state" */
  game_state: Array<Game_State>;
  /** fetch aggregated fields from the table: "game_state" */
  game_state_aggregate: Game_State_Aggregate;
  /** fetch data from the table: "game_state" using primary key columns */
  game_state_by_pk?: Maybe<Game_State>;
  /** fetch data from the table: "mypertamina_fuel_source" */
  mypertamina_fuel_source: Array<Mypertamina_Fuel_Source>;
  /** fetch aggregated fields from the table: "mypertamina_fuel_source" */
  mypertamina_fuel_source_aggregate: Mypertamina_Fuel_Source_Aggregate;
  /** fetch data from the table: "mypertamina_fuel_source" using primary key columns */
  mypertamina_fuel_source_by_pk?: Maybe<Mypertamina_Fuel_Source>;
  /** fetch data from the table: "response_wrapper" */
  response_wrapper: Array<Response_Wrapper>;
  /** fetch aggregated fields from the table: "response_wrapper" */
  response_wrapper_aggregate: Response_Wrapper_Aggregate;
  /** fetch data from the table: "user_info" */
  user_info: Array<User_Info>;
  /** fetch aggregated fields from the table: "user_info" */
  user_info_aggregate: User_Info_Aggregate;
  /** fetch data from the table: "user_info" using primary key columns */
  user_info_by_pk?: Maybe<User_Info>;
};


export type Query_RootBatch_WinnerArgs = {
  distinct_on?: InputMaybe<Array<Batch_Winner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Batch_Winner_Order_By>>;
  where?: InputMaybe<Batch_Winner_Bool_Exp>;
};


export type Query_RootBatch_Winner_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Batch_Winner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Batch_Winner_Order_By>>;
  where?: InputMaybe<Batch_Winner_Bool_Exp>;
};


export type Query_RootBatch_Winner_By_PkArgs = {
  batch_num: Scalars['Int'];
  position: Scalars['Int'];
  vehicle: Scalars['String'];
};


export type Query_RootGame_Progress_LiveArgs = {
  distinct_on?: InputMaybe<Array<Game_Progress_Live_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Progress_Live_Order_By>>;
  where?: InputMaybe<Game_Progress_Live_Bool_Exp>;
};


export type Query_RootGame_Progress_Live_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Game_Progress_Live_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Progress_Live_Order_By>>;
  where?: InputMaybe<Game_Progress_Live_Bool_Exp>;
};


export type Query_RootGame_Progress_Live_By_PkArgs = {
  mobile_number: Scalars['String'];
  vehicle: Scalars['String'];
};


export type Query_RootGame_Progress_Live_CompactArgs = {
  distinct_on?: InputMaybe<Array<Game_Progress_Live_Compact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Progress_Live_Compact_Order_By>>;
  where?: InputMaybe<Game_Progress_Live_Compact_Bool_Exp>;
};


export type Query_RootGame_Progress_Live_Compact_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Game_Progress_Live_Compact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Progress_Live_Compact_Order_By>>;
  where?: InputMaybe<Game_Progress_Live_Compact_Bool_Exp>;
};


export type Query_RootGame_Progress_SnapshotArgs = {
  distinct_on?: InputMaybe<Array<Game_Progress_Snapshot_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Progress_Snapshot_Order_By>>;
  where?: InputMaybe<Game_Progress_Snapshot_Bool_Exp>;
};


export type Query_RootGame_Progress_Snapshot_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Game_Progress_Snapshot_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Progress_Snapshot_Order_By>>;
  where?: InputMaybe<Game_Progress_Snapshot_Bool_Exp>;
};


export type Query_RootGame_Progress_Snapshot_By_PkArgs = {
  mobile_number: Scalars['String'];
  snapshot_key: Scalars['String'];
  vehicle: Scalars['String'];
};


export type Query_RootGame_StateArgs = {
  distinct_on?: InputMaybe<Array<Game_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_State_Order_By>>;
  where?: InputMaybe<Game_State_Bool_Exp>;
};


export type Query_RootGame_State_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Game_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_State_Order_By>>;
  where?: InputMaybe<Game_State_Bool_Exp>;
};


export type Query_RootGame_State_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootMypertamina_Fuel_SourceArgs = {
  distinct_on?: InputMaybe<Array<Mypertamina_Fuel_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mypertamina_Fuel_Source_Order_By>>;
  where?: InputMaybe<Mypertamina_Fuel_Source_Bool_Exp>;
};


export type Query_RootMypertamina_Fuel_Source_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mypertamina_Fuel_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mypertamina_Fuel_Source_Order_By>>;
  where?: InputMaybe<Mypertamina_Fuel_Source_Bool_Exp>;
};


export type Query_RootMypertamina_Fuel_Source_By_PkArgs = {
  trx_id: Scalars['String'];
};


export type Query_RootResponse_WrapperArgs = {
  distinct_on?: InputMaybe<Array<Response_Wrapper_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Response_Wrapper_Order_By>>;
  where?: InputMaybe<Response_Wrapper_Bool_Exp>;
};


export type Query_RootResponse_Wrapper_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Response_Wrapper_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Response_Wrapper_Order_By>>;
  where?: InputMaybe<Response_Wrapper_Bool_Exp>;
};


export type Query_RootUser_InfoArgs = {
  distinct_on?: InputMaybe<Array<User_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Info_Order_By>>;
  where?: InputMaybe<User_Info_Bool_Exp>;
};


export type Query_RootUser_Info_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Info_Order_By>>;
  where?: InputMaybe<User_Info_Bool_Exp>;
};


export type Query_RootUser_Info_By_PkArgs = {
  mobile_number: Scalars['String'];
};

export type Rerank_Vehicle_Args = {
  by_vehicle?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "response_wrapper" */
export type Response_Wrapper = {
  __typename?: 'response_wrapper';
  code?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
};

/** aggregated selection of "response_wrapper" */
export type Response_Wrapper_Aggregate = {
  __typename?: 'response_wrapper_aggregate';
  aggregate?: Maybe<Response_Wrapper_Aggregate_Fields>;
  nodes: Array<Response_Wrapper>;
};

/** aggregate fields of "response_wrapper" */
export type Response_Wrapper_Aggregate_Fields = {
  __typename?: 'response_wrapper_aggregate_fields';
  avg?: Maybe<Response_Wrapper_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Response_Wrapper_Max_Fields>;
  min?: Maybe<Response_Wrapper_Min_Fields>;
  stddev?: Maybe<Response_Wrapper_Stddev_Fields>;
  stddev_pop?: Maybe<Response_Wrapper_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Response_Wrapper_Stddev_Samp_Fields>;
  sum?: Maybe<Response_Wrapper_Sum_Fields>;
  var_pop?: Maybe<Response_Wrapper_Var_Pop_Fields>;
  var_samp?: Maybe<Response_Wrapper_Var_Samp_Fields>;
  variance?: Maybe<Response_Wrapper_Variance_Fields>;
};


/** aggregate fields of "response_wrapper" */
export type Response_Wrapper_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Response_Wrapper_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Response_Wrapper_Avg_Fields = {
  __typename?: 'response_wrapper_avg_fields';
  code?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "response_wrapper". All fields are combined with a logical 'AND'. */
export type Response_Wrapper_Bool_Exp = {
  _and?: InputMaybe<Array<Response_Wrapper_Bool_Exp>>;
  _not?: InputMaybe<Response_Wrapper_Bool_Exp>;
  _or?: InputMaybe<Array<Response_Wrapper_Bool_Exp>>;
  code?: InputMaybe<Int_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Boolean_Comparison_Exp>;
};

/** aggregate max on columns */
export type Response_Wrapper_Max_Fields = {
  __typename?: 'response_wrapper_max_fields';
  code?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Response_Wrapper_Min_Fields = {
  __typename?: 'response_wrapper_min_fields';
  code?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "response_wrapper". */
export type Response_Wrapper_Order_By = {
  code?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** select columns of table "response_wrapper" */
export enum Response_Wrapper_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Message = 'message',
  /** column name */
  Status = 'status'
}

/** aggregate stddev on columns */
export type Response_Wrapper_Stddev_Fields = {
  __typename?: 'response_wrapper_stddev_fields';
  code?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Response_Wrapper_Stddev_Pop_Fields = {
  __typename?: 'response_wrapper_stddev_pop_fields';
  code?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Response_Wrapper_Stddev_Samp_Fields = {
  __typename?: 'response_wrapper_stddev_samp_fields';
  code?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Response_Wrapper_Sum_Fields = {
  __typename?: 'response_wrapper_sum_fields';
  code?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Response_Wrapper_Var_Pop_Fields = {
  __typename?: 'response_wrapper_var_pop_fields';
  code?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Response_Wrapper_Var_Samp_Fields = {
  __typename?: 'response_wrapper_var_samp_fields';
  code?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Response_Wrapper_Variance_Fields = {
  __typename?: 'response_wrapper_variance_fields';
  code?: Maybe<Scalars['Float']>;
};

export type Softroll_Game_Progress_Args = {
  snapshot_key?: InputMaybe<Scalars['String']>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "batch_winner" */
  batch_winner: Array<Batch_Winner>;
  /** fetch aggregated fields from the table: "batch_winner" */
  batch_winner_aggregate: Batch_Winner_Aggregate;
  /** fetch data from the table: "batch_winner" using primary key columns */
  batch_winner_by_pk?: Maybe<Batch_Winner>;
  /** fetch data from the table: "game_progress_live" */
  game_progress_live: Array<Game_Progress_Live>;
  /** fetch aggregated fields from the table: "game_progress_live" */
  game_progress_live_aggregate: Game_Progress_Live_Aggregate;
  /** fetch data from the table: "game_progress_live" using primary key columns */
  game_progress_live_by_pk?: Maybe<Game_Progress_Live>;
  /** fetch data from the table: "game_progress_live_compact" */
  game_progress_live_compact: Array<Game_Progress_Live_Compact>;
  /** fetch aggregated fields from the table: "game_progress_live_compact" */
  game_progress_live_compact_aggregate: Game_Progress_Live_Compact_Aggregate;
  /** fetch data from the table: "game_progress_snapshot" */
  game_progress_snapshot: Array<Game_Progress_Snapshot>;
  /** fetch aggregated fields from the table: "game_progress_snapshot" */
  game_progress_snapshot_aggregate: Game_Progress_Snapshot_Aggregate;
  /** fetch data from the table: "game_progress_snapshot" using primary key columns */
  game_progress_snapshot_by_pk?: Maybe<Game_Progress_Snapshot>;
  /** fetch data from the table: "game_state" */
  game_state: Array<Game_State>;
  /** fetch aggregated fields from the table: "game_state" */
  game_state_aggregate: Game_State_Aggregate;
  /** fetch data from the table: "game_state" using primary key columns */
  game_state_by_pk?: Maybe<Game_State>;
  /** fetch data from the table: "mypertamina_fuel_source" */
  mypertamina_fuel_source: Array<Mypertamina_Fuel_Source>;
  /** fetch aggregated fields from the table: "mypertamina_fuel_source" */
  mypertamina_fuel_source_aggregate: Mypertamina_Fuel_Source_Aggregate;
  /** fetch data from the table: "mypertamina_fuel_source" using primary key columns */
  mypertamina_fuel_source_by_pk?: Maybe<Mypertamina_Fuel_Source>;
  /** fetch data from the table: "response_wrapper" */
  response_wrapper: Array<Response_Wrapper>;
  /** fetch aggregated fields from the table: "response_wrapper" */
  response_wrapper_aggregate: Response_Wrapper_Aggregate;
  /** fetch data from the table: "user_info" */
  user_info: Array<User_Info>;
  /** fetch aggregated fields from the table: "user_info" */
  user_info_aggregate: User_Info_Aggregate;
  /** fetch data from the table: "user_info" using primary key columns */
  user_info_by_pk?: Maybe<User_Info>;
};


export type Subscription_RootBatch_WinnerArgs = {
  distinct_on?: InputMaybe<Array<Batch_Winner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Batch_Winner_Order_By>>;
  where?: InputMaybe<Batch_Winner_Bool_Exp>;
};


export type Subscription_RootBatch_Winner_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Batch_Winner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Batch_Winner_Order_By>>;
  where?: InputMaybe<Batch_Winner_Bool_Exp>;
};


export type Subscription_RootBatch_Winner_By_PkArgs = {
  batch_num: Scalars['Int'];
  position: Scalars['Int'];
  vehicle: Scalars['String'];
};


export type Subscription_RootGame_Progress_LiveArgs = {
  distinct_on?: InputMaybe<Array<Game_Progress_Live_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Progress_Live_Order_By>>;
  where?: InputMaybe<Game_Progress_Live_Bool_Exp>;
};


export type Subscription_RootGame_Progress_Live_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Game_Progress_Live_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Progress_Live_Order_By>>;
  where?: InputMaybe<Game_Progress_Live_Bool_Exp>;
};


export type Subscription_RootGame_Progress_Live_By_PkArgs = {
  mobile_number: Scalars['String'];
  vehicle: Scalars['String'];
};


export type Subscription_RootGame_Progress_Live_CompactArgs = {
  distinct_on?: InputMaybe<Array<Game_Progress_Live_Compact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Progress_Live_Compact_Order_By>>;
  where?: InputMaybe<Game_Progress_Live_Compact_Bool_Exp>;
};


export type Subscription_RootGame_Progress_Live_Compact_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Game_Progress_Live_Compact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Progress_Live_Compact_Order_By>>;
  where?: InputMaybe<Game_Progress_Live_Compact_Bool_Exp>;
};


export type Subscription_RootGame_Progress_SnapshotArgs = {
  distinct_on?: InputMaybe<Array<Game_Progress_Snapshot_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Progress_Snapshot_Order_By>>;
  where?: InputMaybe<Game_Progress_Snapshot_Bool_Exp>;
};


export type Subscription_RootGame_Progress_Snapshot_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Game_Progress_Snapshot_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Progress_Snapshot_Order_By>>;
  where?: InputMaybe<Game_Progress_Snapshot_Bool_Exp>;
};


export type Subscription_RootGame_Progress_Snapshot_By_PkArgs = {
  mobile_number: Scalars['String'];
  snapshot_key: Scalars['String'];
  vehicle: Scalars['String'];
};


export type Subscription_RootGame_StateArgs = {
  distinct_on?: InputMaybe<Array<Game_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_State_Order_By>>;
  where?: InputMaybe<Game_State_Bool_Exp>;
};


export type Subscription_RootGame_State_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Game_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_State_Order_By>>;
  where?: InputMaybe<Game_State_Bool_Exp>;
};


export type Subscription_RootGame_State_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootMypertamina_Fuel_SourceArgs = {
  distinct_on?: InputMaybe<Array<Mypertamina_Fuel_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mypertamina_Fuel_Source_Order_By>>;
  where?: InputMaybe<Mypertamina_Fuel_Source_Bool_Exp>;
};


export type Subscription_RootMypertamina_Fuel_Source_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mypertamina_Fuel_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mypertamina_Fuel_Source_Order_By>>;
  where?: InputMaybe<Mypertamina_Fuel_Source_Bool_Exp>;
};


export type Subscription_RootMypertamina_Fuel_Source_By_PkArgs = {
  trx_id: Scalars['String'];
};


export type Subscription_RootResponse_WrapperArgs = {
  distinct_on?: InputMaybe<Array<Response_Wrapper_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Response_Wrapper_Order_By>>;
  where?: InputMaybe<Response_Wrapper_Bool_Exp>;
};


export type Subscription_RootResponse_Wrapper_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Response_Wrapper_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Response_Wrapper_Order_By>>;
  where?: InputMaybe<Response_Wrapper_Bool_Exp>;
};


export type Subscription_RootUser_InfoArgs = {
  distinct_on?: InputMaybe<Array<User_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Info_Order_By>>;
  where?: InputMaybe<User_Info_Bool_Exp>;
};


export type Subscription_RootUser_Info_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Info_Order_By>>;
  where?: InputMaybe<User_Info_Bool_Exp>;
};


export type Subscription_RootUser_Info_By_PkArgs = {
  mobile_number: Scalars['String'];
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

export type Upsert_User_Info_Args = {
  by_mobile_number?: InputMaybe<Scalars['String']>;
  by_name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "user_info" */
export type User_Info = {
  __typename?: 'user_info';
  created_at: Scalars['timestamptz'];
  kol_chosen_at?: Maybe<Scalars['timestamptz']>;
  kol_chosen_count: Scalars['Int'];
  kol_id?: Maybe<Scalars['Int']>;
  mobile_number: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

/** aggregated selection of "user_info" */
export type User_Info_Aggregate = {
  __typename?: 'user_info_aggregate';
  aggregate?: Maybe<User_Info_Aggregate_Fields>;
  nodes: Array<User_Info>;
};

/** aggregate fields of "user_info" */
export type User_Info_Aggregate_Fields = {
  __typename?: 'user_info_aggregate_fields';
  avg?: Maybe<User_Info_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Info_Max_Fields>;
  min?: Maybe<User_Info_Min_Fields>;
  stddev?: Maybe<User_Info_Stddev_Fields>;
  stddev_pop?: Maybe<User_Info_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Info_Stddev_Samp_Fields>;
  sum?: Maybe<User_Info_Sum_Fields>;
  var_pop?: Maybe<User_Info_Var_Pop_Fields>;
  var_samp?: Maybe<User_Info_Var_Samp_Fields>;
  variance?: Maybe<User_Info_Variance_Fields>;
};


/** aggregate fields of "user_info" */
export type User_Info_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Info_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Info_Avg_Fields = {
  __typename?: 'user_info_avg_fields';
  kol_chosen_count?: Maybe<Scalars['Float']>;
  kol_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_info". All fields are combined with a logical 'AND'. */
export type User_Info_Bool_Exp = {
  _and?: InputMaybe<Array<User_Info_Bool_Exp>>;
  _not?: InputMaybe<User_Info_Bool_Exp>;
  _or?: InputMaybe<Array<User_Info_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  kol_chosen_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  kol_chosen_count?: InputMaybe<Int_Comparison_Exp>;
  kol_id?: InputMaybe<Int_Comparison_Exp>;
  mobile_number?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_info" */
export enum User_Info_Constraint {
  /** unique or primary key constraint */
  UserInfoPkey = 'user_info_pkey'
}

/** input type for incrementing numeric columns in table "user_info" */
export type User_Info_Inc_Input = {
  kol_chosen_count?: InputMaybe<Scalars['Int']>;
  kol_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_info" */
export type User_Info_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  kol_chosen_at?: InputMaybe<Scalars['timestamptz']>;
  kol_chosen_count?: InputMaybe<Scalars['Int']>;
  kol_id?: InputMaybe<Scalars['Int']>;
  mobile_number?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Info_Max_Fields = {
  __typename?: 'user_info_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  kol_chosen_at?: Maybe<Scalars['timestamptz']>;
  kol_chosen_count?: Maybe<Scalars['Int']>;
  kol_id?: Maybe<Scalars['Int']>;
  mobile_number?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Info_Min_Fields = {
  __typename?: 'user_info_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  kol_chosen_at?: Maybe<Scalars['timestamptz']>;
  kol_chosen_count?: Maybe<Scalars['Int']>;
  kol_id?: Maybe<Scalars['Int']>;
  mobile_number?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user_info" */
export type User_Info_Mutation_Response = {
  __typename?: 'user_info_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Info>;
};

/** on conflict condition type for table "user_info" */
export type User_Info_On_Conflict = {
  constraint: User_Info_Constraint;
  update_columns?: Array<User_Info_Update_Column>;
  where?: InputMaybe<User_Info_Bool_Exp>;
};

/** Ordering options when selecting data from "user_info". */
export type User_Info_Order_By = {
  created_at?: InputMaybe<Order_By>;
  kol_chosen_at?: InputMaybe<Order_By>;
  kol_chosen_count?: InputMaybe<Order_By>;
  kol_id?: InputMaybe<Order_By>;
  mobile_number?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_info */
export type User_Info_Pk_Columns_Input = {
  mobile_number: Scalars['String'];
};

/** select columns of table "user_info" */
export enum User_Info_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  KolChosenAt = 'kol_chosen_at',
  /** column name */
  KolChosenCount = 'kol_chosen_count',
  /** column name */
  KolId = 'kol_id',
  /** column name */
  MobileNumber = 'mobile_number',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "user_info" */
export type User_Info_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  kol_chosen_at?: InputMaybe<Scalars['timestamptz']>;
  kol_chosen_count?: InputMaybe<Scalars['Int']>;
  kol_id?: InputMaybe<Scalars['Int']>;
  mobile_number?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type User_Info_Stddev_Fields = {
  __typename?: 'user_info_stddev_fields';
  kol_chosen_count?: Maybe<Scalars['Float']>;
  kol_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Info_Stddev_Pop_Fields = {
  __typename?: 'user_info_stddev_pop_fields';
  kol_chosen_count?: Maybe<Scalars['Float']>;
  kol_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Info_Stddev_Samp_Fields = {
  __typename?: 'user_info_stddev_samp_fields';
  kol_chosen_count?: Maybe<Scalars['Float']>;
  kol_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type User_Info_Sum_Fields = {
  __typename?: 'user_info_sum_fields';
  kol_chosen_count?: Maybe<Scalars['Int']>;
  kol_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "user_info" */
export enum User_Info_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  KolChosenAt = 'kol_chosen_at',
  /** column name */
  KolChosenCount = 'kol_chosen_count',
  /** column name */
  KolId = 'kol_id',
  /** column name */
  MobileNumber = 'mobile_number',
  /** column name */
  Name = 'name'
}

/** aggregate var_pop on columns */
export type User_Info_Var_Pop_Fields = {
  __typename?: 'user_info_var_pop_fields';
  kol_chosen_count?: Maybe<Scalars['Float']>;
  kol_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Info_Var_Samp_Fields = {
  __typename?: 'user_info_var_samp_fields';
  kol_chosen_count?: Maybe<Scalars['Float']>;
  kol_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Info_Variance_Fields = {
  __typename?: 'user_info_variance_fields';
  kol_chosen_count?: Maybe<Scalars['Float']>;
  kol_id?: Maybe<Scalars['Float']>;
};


export const CheckPermissionDocument = gql`
    query CheckPermission {
  game_progress_live_by_pk(vehicle: "", mobile_number: "") {
    created_at
  }
}
    `;

export function useCheckPermissionQuery(options: Omit<Urql.UseQueryArgs<CheckPermissionQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<CheckPermissionQuery>({ query: CheckPermissionDocument, ...options });
};
export const GetParticipantStatsDocument = gql`
    query GetParticipantStats {
  car: game_progress_live_aggregate(where: {vehicle: {_eq: "C"}}) {
    aggregate {
      count(columns: vehicle)
    }
  }
  motorcycle: game_progress_live_aggregate(where: {vehicle: {_eq: "M"}}) {
    aggregate {
      count(columns: vehicle)
    }
  }
  total: game_progress_live_aggregate {
    aggregate {
      count(columns: mobile_number, distinct: true)
    }
  }
}
    `;

export function useGetParticipantStatsQuery(options: Omit<Urql.UseQueryArgs<GetParticipantStatsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetParticipantStatsQuery>({ query: GetParticipantStatsDocument, ...options });
};
export const GetParticipantDocument = gql`
    query GetParticipant($mobileNumber: String!) {
  game_progress_live(where: {mobile_number: {_eq: $mobileNumber}}) {
    created_at
    gender
    kol_id
    lap_count
    lap_progress
    lap_progress_km
    ledger
    mobile_number
    mobile_number_redacted
    name
    order_count
    order_id
    order_increment
    order_kind
    order_product
    order_time
    order_volume
    position
    prev_lap_count
    prev_lap_progress
    prev_lap_progress_km
    prev_order_count
    prev_order_id
    prev_order_increment
    prev_order_kind
    prev_order_product
    prev_order_time
    prev_total_progress
    total_progress
    vehicle
  }
}
    `;

export function useGetParticipantQuery(options: Omit<Urql.UseQueryArgs<GetParticipantQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetParticipantQuery>({ query: GetParticipantDocument, ...options });
};
export const GetLeaderboardsDocument = gql`
    mutation GetLeaderboards($mobileNumber: String!) {
  motorcycle: get_leaderboard(
    args: {by_vehicle: "M", by_mobile_number: $mobileNumber}
  ) {
    self
    mobile_number_redacted
    name
    position
    lap_count
    lap_progress_km
    total_progress
    kol_id
  }
  car: get_leaderboard(args: {by_vehicle: "C", by_mobile_number: $mobileNumber}) {
    self
    mobile_number_redacted
    name
    position
    lap_count
    lap_progress_km
    total_progress
    kol_id
  }
}
    `;

export function useGetLeaderboardsMutation() {
  return Urql.useMutation<GetLeaderboardsMutation, GetLeaderboardsMutationVariables>(GetLeaderboardsDocument);
};
export type CheckPermissionQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckPermissionQuery = { __typename?: 'query_root', game_progress_live_by_pk?: { __typename?: 'game_progress_live', created_at: any } | null };

export type GetParticipantStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetParticipantStatsQuery = { __typename?: 'query_root', car: { __typename?: 'game_progress_live_aggregate', aggregate?: { __typename?: 'game_progress_live_aggregate_fields', count: number } | null }, motorcycle: { __typename?: 'game_progress_live_aggregate', aggregate?: { __typename?: 'game_progress_live_aggregate_fields', count: number } | null }, total: { __typename?: 'game_progress_live_aggregate', aggregate?: { __typename?: 'game_progress_live_aggregate_fields', count: number } | null } };

export type GetParticipantQueryVariables = Exact<{
  mobileNumber: Scalars['String'];
}>;


export type GetParticipantQuery = { __typename?: 'query_root', game_progress_live: Array<{ __typename?: 'game_progress_live', created_at: any, gender?: string | null, kol_id?: number | null, lap_count: number, lap_progress: number, lap_progress_km: any, ledger?: string | null, mobile_number: string, mobile_number_redacted: string, name: string, order_count: number, order_id?: string | null, order_increment?: number | null, order_kind?: string | null, order_product?: string | null, order_time?: any | null, order_volume?: any | null, position?: number | null, prev_lap_count: number, prev_lap_progress: number, prev_lap_progress_km: any, prev_order_count: number, prev_order_id?: string | null, prev_order_increment?: number | null, prev_order_kind?: string | null, prev_order_product?: string | null, prev_order_time?: any | null, prev_total_progress: number, total_progress: number, vehicle: string }> };

export type GetLeaderboardsMutationVariables = Exact<{
  mobileNumber: Scalars['String'];
}>;


export type GetLeaderboardsMutation = { __typename?: 'mutation_root', motorcycle: Array<{ __typename?: 'game_progress_live_compact', self?: boolean | null, mobile_number_redacted?: string | null, name?: string | null, position?: number | null, lap_count?: number | null, lap_progress_km?: any | null, total_progress?: number | null, kol_id?: number | null }>, car: Array<{ __typename?: 'game_progress_live_compact', self?: boolean | null, mobile_number_redacted?: string | null, name?: string | null, position?: number | null, lap_count?: number | null, lap_progress_km?: any | null, total_progress?: number | null, kol_id?: number | null }> };
