import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../@types/auth';
import { createClient } from 'urql';
import { HASURA_GRAPHQL_URL } from 'src/config';
import {
  GetLeaderboardsDocument,
  GetLeaderboardsMutation,
  GetLeaderboardsMutationVariables,
} from 'src/generated/graphql';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

const USER_CUSTOMERSUPPORT: AuthUser = {
  sub: 'customersupport',
  displayName: 'Customer Support',
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          // const response = await axios.get('/api/account/my-account');
          // const { user } = response.data;
          const user = USER_CUSTOMERSUPPORT;
          console.debug('Dispatching Types.Initial', user);
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          console.debug('Dispatching Types.Initial isAuthenticated=false');
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        console.debug('Dispatching Types.Initial');
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    const client = createClient({
      url: HASURA_GRAPHQL_URL,
      fetchOptions: () => ({
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${password}`,
        },
      }),
    });
    console.debug('GetLeaderboardsMutation using...', password);
    const res = await client
      .mutation<GetLeaderboardsMutation, GetLeaderboardsMutationVariables>(
        GetLeaderboardsDocument,
        {
          mobileNumber: '',
        }
      )
      .toPromise();
    if (res.error) {
      console.error('GetLeaderboardsMutation =>', res.error);
      throw res.error;
    }
    console.debug('GetLeaderboardsMutation =>', res.data);

    // const response = await axios.post('/api/account/login', {
    //   email,
    //   password,
    // });
    // const { accessToken, user } = response.data;
    const accessToken = password;
    const user = USER_CUSTOMERSUPPORT;
    setSession(accessToken);
    console.debug('Sign in successful as "customersupport", dispatching Types.Login');
    dispatch({
      type: Types.Login,
      payload: {
        user,
      },
    });
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: Types.Register,
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
