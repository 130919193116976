import { createClient } from 'urql';

export const HASURA_GRAPHQL_URL =
  process.env.REACT_APP_HASURA_GRAPHQL_URL ?? 'http://localhost:8080/v1/graphql';

console.info(`Hasura GraphQL URL: ${HASURA_GRAPHQL_URL}`);

export const client = createClient({
  url: HASURA_GRAPHQL_URL,
  fetchOptions: () => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      throw new Error('JWT accessToken is required');
    }
    return {
      headers: {
        // 'X-Hasura-Admin-Secret': HASURA_GRAPHQL_ADMIN_SECRET,
        Authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
    };
  },
});
